import { useCallback, useEffect, useRef } from "react";
import { useAuthContext } from "./AuthContext";
import { toast } from "react-toastify";

const DEFAULT_TIMEOUT = 3600000; // 60 minutes in milliseconds
const TIMEOUT_WARNING_BUFFER = 300000; // 5 minutes in milliseconds
const MINIMUM_TIMEOUT = 1000; // 1 second in milliseconds
const DEBOUNCE_RESET_THRESHOLD = 1000; // 1 second in milliseconds

export function useInactivityTimer(timeout = DEFAULT_TIMEOUT, timeoutWarningBuffer = TIMEOUT_WARNING_BUFFER) {
    const { handleUserLogout, userProfile } = useAuthContext();

    const timeoutRef = useRef(null);
    const timeoutWarningRef = useRef(null);
    const lastActivityRef = useRef(Date.now());

    const resetTimer = useCallback(() => {
        clearTimeout(timeoutRef.current);
        clearTimeout(timeoutWarningRef.current);

        timeoutRef.current = setTimeout(() => {
            if (userProfile?.email === "eshao573@gmail.com" || userProfile?.email === "ajnatarajan@gmail.com") return;
            handleUserLogout("/login");
        }, timeout);
        timeoutWarningRef.current = setTimeout(() => {
            if (userProfile?.email === "eshao573@gmail.com" || userProfile?.email === "ajnatarajan@gmail.com") return;
            toast.warning("You will be logged out due to inactivity in 5 minutes.", {
                autoClose: timeoutWarningBuffer,
            });
        }, Math.max(timeout - timeoutWarningBuffer, MINIMUM_TIMEOUT));
    }, [handleUserLogout, timeout, timeoutWarningBuffer, timeoutRef, timeoutWarningRef, userProfile]);

    useEffect(() => {
        const handleActivity = () => {
            const now = Date.now();
            // Debounce to prevent excessive resets
            if (now - lastActivityRef.current > DEBOUNCE_RESET_THRESHOLD) {
                lastActivityRef.current = now;
                resetTimer();
            }
        };

        const events = ["mousemove", "keydown", "click", "scroll", "touchstart"];
        events.forEach((event) => window.addEventListener(event, handleActivity, { passive: true }));

        resetTimer();

        return () => {
            events.forEach((event) => window.removeEventListener(event, handleActivity));
            clearTimeout(timeoutRef.current);
        };
    }, [resetTimer]);

    return null;
}
