import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
    $getSelection,
    $isRangeSelection,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND,
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../styles/editor/Editor.module.css";
import { $getRoot } from "lexical";

import { useApplicationContext } from "../application/ApplicationContext.js";
import BrainstormIcon from "../../imgs/brainstorm-icon.png";
import QuestionIcon from "../../imgs/question-icon.png";
import GradeIcon from "../../imgs/grade-icon.png";
import GradeIconDisabled from "../../imgs/grade-icon-disabled.png";
import { Tooltip, TooltipContent, TooltipTrigger } from "../base/Tooltip.js";
import { FloatingDelayGroup } from "@floating-ui/react";

import { toast } from "react-toastify";

const LowPriority = 1;

function ActionButtons({ wordCount }) {
    const {
        applicationInfo,
        editorStateStr,
        handleSubmitBrainstormRequest,
        handleSubmitGradeRequest,
        handleSubmitProgressCheckRequest,
        isUserVerified,
        handleEmailVerification,
    } = useApplicationContext();
    const handleBrainstormClick = () => {
        handleSubmitBrainstormRequest();
    };

    /*
    We will not add the "Ask" feature:
    1. Low value: gpt api is not connected to the internet. Users can search up information on
    their own via the Internet.
    2. Unethical misuse of AI: students can bypass our guardrails to get Athena to write snippets
    of their essays for them. This violates our ethics hardline.
    */

    const handleProgressCheckClick = () => {
        handleSubmitProgressCheckRequest();
    };

    const handleGradeClick = () => {
        try {
            handleSubmitGradeRequest(editorStateStr);
        } catch (error) {
            toast.error(error.message);
            return;
        }
    };

    const handleLinkClick = () => {
        handleEmailVerification();
    };

    const hasUserStartedEssay = wordCount > 0;
    const isAIAllowed = applicationInfo.isAIAllowed;

    function brainstormTooltip() {
        const isDisabled = !isAIAllowed || hasUserStartedEssay;
        let tooltipText;

        if (!isAIAllowed) {
            tooltipText = `${applicationInfo.schoolName} does not allow AI assistance`;
        } else if (hasUserStartedEssay) {
            tooltipText = "Only available before you start writing";
        } else {
            tooltipText = "Personalized ideas to start your essay";
        }

        return (
            <Tooltip>
                <TooltipTrigger
                    className={`${styles.action_button_secondary} ${isDisabled && styles.disabled_button}`}
                    onClick={handleBrainstormClick}
                    disabled={isDisabled}
                >
                    <img src={BrainstormIcon} alt="Brainstorm Icon" />
                    Brainstorm
                </TooltipTrigger>
                <TooltipContent>{tooltipText}</TooltipContent>
            </Tooltip>
        );
    }

    function progressCheckTooltip() {
        const isDisabled = !isAIAllowed || !hasUserStartedEssay;
        let tooltipText;

        if (!isAIAllowed) {
            tooltipText = `${applicationInfo.schoolName} does not allow AI assistance`;
        } else if (hasUserStartedEssay) {
            tooltipText = "Only available after you start writing";
        } else {
            tooltipText = "Tips to improve your essay draft";
        }

        return (
            <Tooltip>
                <TooltipTrigger
                    className={`${styles.action_button_secondary} ${isDisabled && styles.disabled_button}`}
                    onClick={handleProgressCheckClick}
                    disabled={isDisabled}
                >
                    <img src={QuestionIcon} alt="Question Icon" />
                    Progress Check
                </TooltipTrigger>
                <TooltipContent>{tooltipText}</TooltipContent>
            </Tooltip>
        );
    }

    function gradeTooltip() {
        const isDisabled = !isAIAllowed || !isUserVerified;
        let tooltipText;
        let icon = isDisabled ? GradeIconDisabled : GradeIcon;

        if (!isAIAllowed) {
            tooltipText = `${applicationInfo.schoolName} does not allow AI assistance`;
        } else if (!isUserVerified) {
            tooltipText = (
                <>
                    You must verify your email to grade!
                    <br />
                    <span onClick={handleLinkClick} className={styles.link_text}>
                        Click to send verification email
                    </span>
                </>
            );
        } else {
            tooltipText = "A detailed report card on your essay";
        }

        return (
            <Tooltip>
                <TooltipTrigger
                    className={`${styles.action_button} ${isDisabled && styles.action_button_disabled}`}
                    onClick={handleGradeClick}
                    disabled={isDisabled}
                >
                    <img src={icon} alt="Grade Icon" />
                    Grade
                </TooltipTrigger>
                <TooltipContent>{tooltipText}</TooltipContent>
            </Tooltip>
        );
    }

    return (
        <div className={styles.action_buttons}>
            <FloatingDelayGroup delay={200}>
                {brainstormTooltip()}
                {progressCheckTooltip()}
                {gradeTooltip()}
            </FloatingDelayGroup>
        </div>
    );
}

function WordCounterPlugin(props) {
    const { editor, wordCount, setWordCount } = props;

    const countWords = (text) => {
        return text.trim().split(/\s+/).filter(Boolean).length;
    };

    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                const words = countWords($getRoot().getTextContent());
                setWordCount(words);
            });
        });
    }, [editor]);
    return (
        <div className={styles.word_count}>
            {wordCount} {wordCount === 1 ? "word" : "words"}
        </div>
    );
}

function Divider() {
    return <div className={styles.divider} />;
}

export default function ToolbarPlugin(props) {
    const [editor] = useLexicalComposerContext();
    const toolbarRef = useRef(null);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [wordCount, setWordCount] = useState(0);

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
        }
    }, []);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, _newEditor) => {
                    $updateToolbar();
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                LowPriority
            )
        );
    }, [editor, $updateToolbar]);

    return (
        <div className={styles.toolbar} ref={toolbarRef}>
            <button
                disabled={!canUndo}
                onClick={() => {
                    editor.dispatchCommand(UNDO_COMMAND, undefined);
                }}
                className={`${styles.toolbar_item} ${styles.spaced}`}
                aria-label="Undo"
            >
                <i className={`${styles.format} ${styles.undo}`} />
            </button>
            <button
                disabled={!canRedo}
                onClick={() => {
                    editor.dispatchCommand(REDO_COMMAND, undefined);
                }}
                className={styles.toolbar_item}
                aria-label="Redo"
            >
                <i className={`${styles.format} ${styles.redo}`} />
            </button>
            <Divider />
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                }}
                className={`${styles.toolbar_item} ${styles.spaced} ${isBold && styles.active}`}
                aria-label="Format Bold"
            >
                <i className={`${styles.format} ${styles.bold}`} />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                }}
                className={`${styles.toolbar_item} ${styles.spaced} ${isItalic && styles.active}`}
                aria-label="Format Italics"
            >
                <i className={`${styles.format} ${styles.italic}`} />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                }}
                className={`${styles.toolbar_item} ${styles.spaced} ${isUnderline && styles.active}`}
                aria-label="Format Underline"
            >
                <i className={`${styles.format} ${styles.underline}`} />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                }}
                className={`${styles.toolbar_item} ${styles.spaced} ${isStrikethrough && styles.active}`}
                aria-label="Format Strikethrough"
            >
                <i className={`${styles.format} ${styles.strikethrough}`} />
            </button>
            <Divider />
            {/*
            The Common App text editor does not support text alignment features
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                }}
                className={`${styles.toolbar_item} ${styles.spaced}`}
                aria-label="Left Align"
            >
                <i className={`${styles.format} ${styles.left_align}`} />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                }}
                className={`${styles.toolbar_item} ${styles.spaced}`}
                aria-label="Center Align"
            >
                <i className={`${styles.format} ${styles.center_align}`} />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                }}
                className={`${styles.toolbar_item} ${styles.spaced}`}
                aria-label="Right Align"
            >
                <i className={`${styles.format} ${styles.right_align}`} />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
                }}
                className={styles.toolbar_item}
                aria-label="Justify Align"
            >
                <i className={`${styles.format} ${styles.justify_align}`} />
            </button>{" "}
            <Divider />
            */}
            <WordCounterPlugin editor={editor} wordCount={wordCount} setWordCount={setWordCount} />
            <Divider />
            <ActionButtons wordCount={wordCount} />
        </div>
    );
}
