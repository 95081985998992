import styles from "../../../styles/main/GradingPage.module.css";
import GradeRequestTypeButton from "../../gradeRequestTypeButton/GradeRequestTypeButton";
import { useEffect, useState, useRef, useContext } from "react";
import { v5 as uuidv5 } from "uuid";
import { useAuthContext } from "../../auth/AuthContext";
import { gradeSupplementalEssay, pollResponse } from "../../../api/apiCalls";

import GreenCheckMark from "../subcomponents/GreenCheckMark";
import LoadingSection from "../subcomponents/LoadingSection";
import ResponseFeedback from "../subcomponents/ResponseFeedback";
import BackArrowButton from "../subcomponents/BackArrowButton";
import InterpretFeedbackHyperlink from "../subcomponents/InterpretFeedbackHyperlink";
import ReactMarkdown from "react-markdown";

import { SidebarContext } from "../../sidebar/SidebarContext";

import {
    STATIC_ATHENA_NAMESPACE_UUID,
    ESSAY_MAX_LENGTH_CHARS,
    PROMPT_MAX_LENGTH_CHARS,
    FormatModelResponse,
    ExtractErrorMessage,
} from "../utils/utils";

/* THIS SHOULD BE DEPRECATED */
function SupplementalEssayGradingContainer(props) {
    const { userProfile } = useAuthContext();
    const { fetchHistoryItemPreviews } = useContext(SidebarContext);

    const [currentRequestID, setCurrentRequestID] = useState("");
    const [modelResponse, setModelResponse] = useState("");
    const [gradeErrorMessage, setGradeErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const errorMessageRef = useRef(null);

    useEffect(() => {
        let intervalId;

        const shouldPollResponse = currentRequestID !== "" && modelResponse === "" && gradeErrorMessage === "";

        function handlePollResponse(data) {
            if (data.gpt_response_status === "finished") {
                setModelResponse(data.model_response);
                fetchHistoryItemPreviews(); // This will retrigger sidebar history preview
                setIsLoading(false);
            } else if (data.gpt_response_status === "error") {
                setGradeErrorMessage(data.error_reason);
                setIsLoading(false);
            }
        }

        if (shouldPollResponse) {
            // Set up the interval
            intervalId = setInterval(() => {
                pollResponse(currentRequestID)
                    .then((response) => {
                        handlePollResponse(response.data);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setGradeErrorMessage(ExtractErrorMessage(error));
                    });
            }, 10000); // 10000 milliseconds = 10 seconds
        }

        // Clean up the interval on unmount or when currentRequestID changes
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [
        currentRequestID,
        modelResponse,
        gradeErrorMessage,
        fetchHistoryItemPreviews,
        setModelResponse,
        setIsLoading,
        setGradeErrorMessage,
    ]); // Effect depends on currentRequestID

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setModelResponse("");
        setGradeErrorMessage("");

        const schoolCode = props.schoolCode;
        const timestamp = new Date().toISOString();

        const idempotencyKey = uuidv5(
            `${schoolCode}-${userProfile.email}-${timestamp.slice(0, 19)}`,
            STATIC_ATHENA_NAMESPACE_UUID
        );

        const formattedFormData = {
            // Given this is the old supplemental essay grading page, I don't think we'll ever run into a situation where membership_plan is not pro
            school_code: userProfile?.membership_plan === "pro" ? schoolCode : "flagship",
            application_school_code: schoolCode,
            idempotency_key: idempotencyKey,
            supplemental_essay: {
                prompt: formData.supplemental_essay_prompt,
                response: formData.supplemental_essay_response,
            },
            max_word_count: formData.max_word_count,
            essay_id: null,
        };

        gradeSupplementalEssay(formattedFormData)
            .then((response) => {
                setCurrentRequestID(response.data.request_id);
            })
            .catch((error) => {
                setIsLoading(false);
                setGradeErrorMessage(ExtractErrorMessage(error));
            });
    };

    useEffect(() => {
        if (gradeErrorMessage && errorMessageRef.current) {
            errorMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [gradeErrorMessage, errorMessageRef]);

    const [formData, setFormData] = useState({
        supplemental_essay_prompt: "",
        supplemental_essay_response: "",
        max_word_count: "",
    });

    // Function to handle changes to the input fields
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleKeyDown = (event) => {
        if (event.key === "Tab") {
            event.preventDefault();
            const { name, selectionStart: start, selectionEnd: end } = event.target;

            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: prevFormData[name].substring(0, start) + "\t" + prevFormData[name].substring(end),
            }));

            setTimeout(() => {
                event.target.selectionStart = event.target.selectionEnd = start + 1;
            }, 0);
        }
    };

    return (
        <div className={styles.screen2_grading_form_container}>
            <div className={styles.screen2_grading_form_title}>
                Step 3: Get your essay graded with actionable feedback!
            </div>
            <div className={styles.screen2_grading_form_subtitle}>{props.subtitle}</div>
            <form className={styles.screen2_grading_form} onSubmit={handleSubmit}>
                <div className={styles.screen2_field_container}>
                    <h2 className={styles.screen2_input_field_title}>Essay Prompt</h2>
                    <textarea
                        className={styles.screen2_prompt_input_field}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        name="supplemental_essay_prompt"
                        value={formData.supplemental_essay_prompt}
                        placeholder="Put the essay prompt here..."
                        maxLength={PROMPT_MAX_LENGTH_CHARS}
                    />
                </div>
                <div className={styles.screen2_field_container}>
                    <h2 className={styles.screen2_input_field_title}>Essay Response</h2>
                    <textarea
                        className={styles.screen2_essay_input_field}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        name="supplemental_essay_response"
                        value={formData.supplemental_essay_response}
                        placeholder="Let's see what you got..."
                        maxLength={ESSAY_MAX_LENGTH_CHARS}
                    />
                </div>
                <div className={styles.screen2_field_container}>
                    <h2 className={styles.screen2_input_field_title}>Maximum Word Count</h2>
                    <div className={styles.screen2_input_field_title_subtext}>
                        Enter the maximum number of words allowed in your response. We use this information to more
                        appropriately grade your essay.
                    </div>
                    <input
                        className={styles.screen2_max_word_count_input_field}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        name="max_word_count"
                        value={formData.max_word_count}
                        placeholder="e.g. 250"
                        maxLength={5}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                    />
                </div>
                {isLoading ? (
                    <LoadingSection shouldShowText={true} />
                ) : modelResponse ? (
                    <GreenCheckMark />
                ) : (
                    <div className={styles.screen2_grade_request_section}>
                        <GradeRequestTypeButton
                            title={"Grade"}
                            cost_in_credits={1}
                            type={"submit"}
                            className={styles.screen2_grade_button}
                        />
                    </div>
                )}
            </form>
            {modelResponse && (
                <div>
                    <InterpretFeedbackHyperlink />
                    <div className={styles.screen2_grading_model_response_container}>
                        <ReactMarkdown>{FormatModelResponse(modelResponse)}</ReactMarkdown>
                    </div>
                </div>
            )}
            {gradeErrorMessage && (
                <div ref={errorMessageRef} className={styles.grade_error_message}>
                    {gradeErrorMessage}
                </div>
            )}
            {modelResponse && <ResponseFeedback current_request_id={currentRequestID} />}
        </div>
    );
}

export default function SupplementalEssayGradingPage(props) {
    return (
        <div>
            <BackArrowButton />
            <SupplementalEssayGradingContainer schoolCode={props.schoolCode} subtitle={props.subtitle} />
        </div>
    );
}
