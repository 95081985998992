import styles from "../styles/App.module.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "./auth/AuthContext";
import { UserProvider } from "./auth/UserContext";
import { ApplicationProvider } from "./application/ApplicationContext";
import { useAuthContext } from "./auth/AuthContext";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import AdminB2BActions from "./auth/AdminB2BActions";
import AdminDashboard from "./auth/AdminDashboard";
import AuthPage from "./auth/AuthPage";
import FlagshipModelSelectionPage from "./main/flagship/ModelSelectionPage";
import PricingPage from "./pricing/PricingPage";
import ContentHome from "./content/ContentHome";
import ClusterSelectionPage from "./clusterSelection/ClusterSelectionPage";
import Demo from "./nacacDemo/Demo";
import NacacDemo from "./nacacDemo/NacacDemo";
import EmailVerification from "./auth/EmailVerification";
import Testing from "./auth/Testing";
import UserSettings from "./admin/UserSettings";
import CleverOAuthPage from "./auth/CleverOAuthPage";
import BuyNowPage from "./auth/BuyNowPage";

// Athena V2
import ApplicantBackground from "./applicant/ApplicantBackground";
import { ApplicationInitializer } from "./application/Application";
import MyApplications from "./application/MyApplications";
import { PlaygroundV2 } from "./playground/Playground";
import CommonApp from "./application/CommonApp";
import EducationalResources from "./education/resources/EducationalResources";

// Grading Pages
import CommonAppEssayGradingPage from "./main/flagship/CommonAppEssayGradingPage";
import SupplementalEssayGradingPage from "./main/flagship/SupplementalEssayGradingPage";
import SchoolSpecificGradingPage from "./main/schoolSpecific/SchoolSpecificGradingPage";

// History View Page
import HistoryItemPage from "./main/history/HistoryItemPage";

// Consultant UI
import ConsultantSettingsPage from "./consultant/ConsultantSettingsPage";

// Static Articles
import {
    YoureThinkingAboutTheAdmissionsProcessIncorrectly,
    StaticArticlePath as YoureThinkingAboutTheAdmissionsProcessIncorrectlyURL,
} from "./content/articles/YoureThinkingAboutTheAdmissionsProcessIncorrectly";
import {
    WhatYouNeedToBeDoingNowAsA2ndSemesterJunior,
    StaticArticlePath as WhatYouNeedToBeDoingNowAsA2ndSemesterJuniorURL,
} from "./content/articles/WhatYouNeedToBeDoingNowAsA2ndSemesterJunior";
import {
    HowAIWillAffectThisUpcomingAdmissionsCycle,
    StaticArticlePath as HowAIWillAffectThisUpcomingAdmissionsCycleURL,
} from "./content/articles/HowAIWillAffectThisUpcomingAdmissionsCycle";
import {
    TheBiggestMistakeEveryApplicantMakes,
    StaticArticlePath as TheBiggestMistakeEveryApplicantMakesURL,
} from "./content/articles/TheBiggestMistakeEveryApplicantMakes";
import {
    InterviewWithAnIvyLeagueAdmissionsOfficer,
    StaticArticlePath as InterviewWithAnIvyLeagueAdmissionsOfficerURL,
} from "./content/articles/InterviewWithAnIvyLeagueAdmissionsOfficer";
import {
    HowToInterpretAthenaFeedback,
    StaticArticlePath as HowToInterpretAthenaFeedbackURL,
} from "./content/articles/HowToInterpretAthenaFeedback";

// Static Components
import SideBar from "./sidebar/Sidebar";

import { useEffect } from "react";
import { AccountSetup } from "./auth/AccountSetup";
import { SidebarProvider } from "./sidebar/SidebarContext";
import AthenaToastContainer from "./base/Toast";
import { useInactivityTimer } from "./auth/InactivityTimer";

function App() {
    const clientId = process.env.REACT_APP_GSI_CLIENT_ID;
    return (
        <div className={styles.App}>
            <GoogleOAuthProvider clientId={clientId}>
                <UserProvider>
                    <Router>
                        <AuthProvider>
                            <PageComponent />
                        </AuthProvider>
                        <AthenaToastContainer />
                    </Router>
                </UserProvider>
            </GoogleOAuthProvider>
        </div>
    );
}

function PageComponent() {
    const { isLoggedIn, handleAuthenticationCheck } = useAuthContext();
    const location = useLocation();
    useEffect(() => {
        handleAuthenticationCheck();
    }, [handleAuthenticationCheck]);

    const isInBuyNowFlow = location.pathname.startsWith("/buy-now");

    return (
        <SidebarProvider>
            {isLoggedIn ? (
                <div className={styles.main_container}>
                    {!isInBuyNowFlow && <SideBar />}
                    <div className={`${styles.main_section_container_v2} ${isInBuyNowFlow && styles.adjust_margin}`}>
                        <Routing />
                    </div>
                </div>
            ) : (
                <div className={styles.auth_container}>
                    {/* <AuthRouting /> temporarily using main Routing as we fix the loggedIn bug */}
                    <Routing />
                </div>
            )}
        </SidebarProvider>
    );
}

function AuthRouting() {
    return (
        <Routes>
            <Route path="/" element={<AuthPage authView={"login"} />} />
            <Route path="/login" element={<AuthPage authView={"login"} />} />
            <Route path="/signup" element={<AuthPage authView={"signup"} />} />
            <Route path="/forgot-password" element={<AuthPage authView={"forgot-password"} />} />
            <Route path="/reset-password" element={<AuthPage authView={"reset-password"} />} />
            <Route path="/email-verification" element={<EmailVerification />} />
            {/* <Route path="*" element={<Navigate to="/login" replace />} /> */}
        </Routes>
    );
}

function Routing({ refreshKey }) {
    const { isLoggedIn } = useAuthContext();

    // Log user out after 60 minutes of inactivity. This is a security measure to prevent unauthorized access.
    // and was requested by LAUSD.
    useInactivityTimer();

    // Auto scroll to top for a new route. Annoying if your previous scroll position
    // persists when you navigate to the next page.
    window.scrollTo(0, 0);

    // TESTING LOCATION
    // const location = useLocation();
    // const [previousLocation, setPreviousLocation] = useState(location);
    // const [modalOpen, setModalOpen] = useState(false);
    // const navigate = useNavigate();

    // useEffect(() => {
    //     if (location.pathname === "/settings") {
    //         if (!modalOpen) {
    //             setPreviousLocation(location.state?.background || location);
    //         }
    //         setModalOpen(true);
    //     } else {
    //         setModalOpen(false);
    //     }
    // }, [location, modalOpen]);

    // const closeModal = () => {
    //     navigate(previousLocation.pathname || "/", { replace: true });
    // };

    return (
        <Routes>
            <Route path="/demo" element={<Demo />} />
            <Route path="/nacac-demo" element={<NacacDemo />} />
            <Route path="/" element={isLoggedIn ? <MyApplications /> : <AuthPage authView={"login"} />} />
            <Route
                path="/essay-grading"
                element={isLoggedIn ? <ClusterSelectionPage /> : <AuthPage authView={"login"} />}
            />
            <Route path="/login" element={<AuthPage authView={"login"} />} />
            <Route path="/signup" element={<AuthPage authView={"signup"} />} />
            <Route path="/forgot-password" element={<AuthPage authView={"forgot-password"} />} />
            <Route path="/reset-password" element={<AuthPage authView={"reset-password"} />} />
            <Route path="/buy-now" element={<BuyNowPage />} />
            <Route
                path="/pricing"
                element={isLoggedIn ? <PricingPage shouldOpenInNewTab={true} /> : <AuthPage authView={"login"} />}
            />
            <Route path="/content" element={isLoggedIn ? <ContentHome /> : <AuthPage authView={"login"} />} />
            <Route path="/account-setup" element={isLoggedIn ? <AccountSetup /> : <AuthPage authView={"login"} />} />
            <Route path="/email-verification" element={<EmailVerification />} />
            {/* <Route path="/settings" element={isLoggedIn ? <UserSettings /> : <AuthPage authView={"login"} />} /> */}
            <Route path="/settings" element={isLoggedIn ? <UserSettings /> : <AuthPage authview={"login"} />} />
            {/* Essay Grading */}
            <Route
                path="/essay-grading/flagship"
                element={isLoggedIn ? <FlagshipModelSelectionPage /> : <AuthPage authView={"login"} />}
            />
            <Route
                path="/essay-grading/flagship/common-app"
                element={isLoggedIn ? <CommonAppEssayGradingPage /> : <AuthPage authView={"login"} />}
            />
            <Route
                path="/essay-grading/flagship/supplemental"
                element={
                    isLoggedIn ? (
                        <SupplementalEssayGradingPage schoolCode={"flagship"} subtitle={"Supplemental Essay"} />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/princeton"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"princeton"}
                            ready={false}
                            subtitle={"Princeton Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/harvard"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"harvard"}
                            ready={false}
                            subtitle={"Harvard Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/yale"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"yale"}
                            ready={false}
                            subtitle={"Yale Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/upenn"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"upenn"}
                            ready={false}
                            subtitle={"UPenn Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/brown"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"brown"}
                            ready={false}
                            subtitle={"Brown Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/columbia"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"columbia"}
                            ready={false}
                            subtitle={"Columbia Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/cornell"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"cornell"}
                            ready={false}
                            subtitle={"Cornell Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/dartmouth"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"dartmouth"}
                            ready={false}
                            subtitle={"Dartmouth Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/mit"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage schoolCode={"mit"} ready={false} subtitle={"MIT Essay"} />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/stanford"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"stanford"}
                            ready={false}
                            subtitle={"Stanford Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/caltech"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"caltech"}
                            ready={false}
                            subtitle={"Caltech Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/jhu"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"jhu"}
                            ready={true}
                            subtitle={"JHU Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/duke"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"duke"}
                            ready={false}
                            subtitle={"Duke Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/northwestern"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"northwestern"}
                            ready={false}
                            subtitle={"Northwestern Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/uchicago"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"uchicago"}
                            ready={false}
                            subtitle={"University of Chicago Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/uc"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"uc"}
                            ready={true}
                            subtitle={"UC Personal Insight Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/rice"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"rice"}
                            ready={false}
                            subtitle={"Rice Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/vanderbilt"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"vanderbilt"}
                            ready={false}
                            subtitle={"Vanderbilt Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/notredame"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"notredame"}
                            ready={false}
                            subtitle={"University of Notre Dame Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/umich"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"umich"}
                            ready={false}
                            subtitle={"University of Michigan — Ann Arbor Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/georgetown"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"georgetown"}
                            ready={false}
                            subtitle={"Georgetown Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/usc"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"usc"}
                            ready={false}
                            subtitle={"USC Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/essay-grading/cmu"
                element={
                    isLoggedIn ? (
                        <SchoolSpecificGradingPage
                            schoolCode={"cmu"}
                            ready={false}
                            subtitle={"CMU Supplemental Essay"}
                        />
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />

            {/* Content */}
            <Route
                path={YoureThinkingAboutTheAdmissionsProcessIncorrectlyURL}
                element={
                    isLoggedIn ? <YoureThinkingAboutTheAdmissionsProcessIncorrectly /> : <AuthPage authView={"login"} />
                }
            />
            <Route
                path={WhatYouNeedToBeDoingNowAsA2ndSemesterJuniorURL}
                element={isLoggedIn ? <WhatYouNeedToBeDoingNowAsA2ndSemesterJunior /> : <AuthPage authView={"login"} />}
            />
            <Route
                path={HowAIWillAffectThisUpcomingAdmissionsCycleURL}
                element={isLoggedIn ? <HowAIWillAffectThisUpcomingAdmissionsCycle /> : <AuthPage authView={"login"} />}
            />
            <Route
                path={TheBiggestMistakeEveryApplicantMakesURL}
                element={isLoggedIn ? <TheBiggestMistakeEveryApplicantMakes /> : <AuthPage authView={"login"} />}
            />
            <Route
                path={InterviewWithAnIvyLeagueAdmissionsOfficerURL}
                element={isLoggedIn ? <InterviewWithAnIvyLeagueAdmissionsOfficer /> : <AuthPage authView={"login"} />}
            />
            <Route
                path={HowToInterpretAthenaFeedbackURL}
                element={isLoggedIn ? <HowToInterpretAthenaFeedback /> : <AuthPage authView={"login"} />}
            />

            {/* Content */}
            <Route
                path="/history/item/:id"
                element={isLoggedIn ? <HistoryItemPage /> : <AuthPage authView={"login"} />}
            />

            {/* ADMIN TESTING */}
            <Route path="/kyle" element={<Testing />} />
            <Route path="/eggs" element={isLoggedIn ? <AdminDashboard /> : <AuthPage authView={"login"} />} />
            <Route path="/eggs/b2b" element={isLoggedIn ? <AdminB2BActions /> : <AuthPage authView={"login"} />} />

            {/* Athena V2 */}
            <Route
                path="/background"
                element={isLoggedIn ? <ApplicantBackground /> : <AuthPage authView={"login"} />}
            />
            <Route path="/applications" element={isLoggedIn ? <MyApplications /> : <AuthPage authView={"login"} />} />
            <Route
                path="/applications/:id"
                element={
                    isLoggedIn ? (
                        <ApplicationProvider key={"college-application"}>
                            <ApplicationInitializer />
                        </ApplicationProvider>
                    ) : (
                        <AuthPage authView={"login"} />
                    )
                }
            />
            <Route
                path="/common-app"
                element={
                    isLoggedIn ? (
                        <ApplicationProvider key={"common-app"}>
                            <CommonApp />
                        </ApplicationProvider>
                    ) : (
                        <AuthPage authView="login" />
                    )
                }
            />
            <Route
                path="/playground"
                element={
                    isLoggedIn ? (
                        <ApplicationProvider key={"playground"}>
                            <PlaygroundV2 />
                        </ApplicationProvider>
                    ) : (
                        <AuthPage authView="login" />
                    )
                }
            />
            <Route
                path="/educational-resources"
                element={isLoggedIn ? <EducationalResources /> : <AuthPage authView="login" />}
            />
            {/* Clever Auth */}
            <Route path="/oauth/clever" element={<CleverOAuthPage />} />
            {/* Consultant UI */}
            <Route
                path="/consultant/settings"
                element={isLoggedIn ? <ConsultantSettingsPage /> : <AuthPage authView="login" />}
            />
        </Routes>
    );
}

export default App;
