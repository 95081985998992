import styles from "../../styles/auth/AccountSetup.module.css";
import BaseButton from "../base/BaseButton";
import { useState } from "react";
import { useAuthContext } from "./AuthContext";
import whiteAthenaV2 from "../../imgs/white_athena_v2.png";

function AccountTypeOptions(props) {
    const { selectedOption, setSelectedOption } = props;
    return (
        <div className={styles.account_type_options_container}>
            <div
                className={`${styles.account_type_option} ${selectedOption === "student" ? styles.selected : null}`}
                onClick={() => {
                    setSelectedOption("student");
                }}
            >
                Student
            </div>
            <div
                className={`${styles.account_type_option} ${selectedOption === "parent" ? styles.selected : null}`}
                onClick={() => {
                    setSelectedOption("parent");
                }}
            >
                Parent
            </div>
            <div
                className={`${styles.account_type_option} ${selectedOption === "other" ? styles.selected : null}`}
                onClick={() => {
                    setSelectedOption("other");
                }}
            >
                Other
            </div>
        </div>
    );
}

function AccountSetupFlow({ flow, handleAccountSetup }) {
    const [studentFlowFormData, setStudentFlowFormData] = useState({
        parent_email: "",
        additional_info: "",
        referral_source: "",
    });
    const [parentFlowFormData, setParentFlowFormData] = useState({
        student_email: "",
        additional_info: "",
        referral_source: "",
    });
    const [otherFormData, setOtherFormData] = useState({
        info: "",
        referral_source: "",
    });

    const handleStudentFlowAdditionalInfoInputChange = (event) => {
        event.preventDefault();
        setStudentFlowFormData((prevFormData) => ({
            ...prevFormData,
            additional_info: event.target.value,
        }));
    };
    const handleStudentFlowReferralSourceInputChange = (event) => {
        event.preventDefault();
        setStudentFlowFormData((prevFormData) => ({
            ...prevFormData,
            referral_source: event.target.value,
        }));
    };
    const handleStudentFlowSubmit = (event) => {
        event.preventDefault();
        const formattedFormData = {
            account_type: "student",
            additional_info:
                "Additional info: " +
                studentFlowFormData.additional_info +
                "; Referral source: " +
                studentFlowFormData.referral_source,
        };
        handleAccountSetup(formattedFormData);
    };
    const studentFlow = (
        <form onSubmit={(event) => handleStudentFlowSubmit(event)}>
            <label htmlFor="additionalInfoInput">What schools are you interested in?</label>
            <textarea
                type="text"
                id="additionalInfoInput"
                name="additionalInfo"
                placeholder="Athena University, Harvard, UCSD, etc."
                value={studentFlowFormData.additional_info}
                onChange={(e) => handleStudentFlowAdditionalInfoInputChange(e)}
                className={styles.account_setup_textarea}
                maxLength={1000}
            ></textarea>
            <br />
            <label htmlFor="referralSourceInput">How did you hear about us?</label>
            <textarea
                type="text"
                id="referralSourceInput"
                name="referralSource"
                placeholder="Tiktok"
                value={studentFlowFormData.referral_source}
                onChange={(e) => handleStudentFlowReferralSourceInputChange(e)}
                className={styles.account_setup_textarea}
                maxLength={1000}
            ></textarea>
            <BaseButton className={styles.setup_submit_button}>
                <img className={styles.action_button_icon} src={whiteAthenaV2} alt="white athena v2 icon" />
                <div className={styles.action_button_text}>Get Started</div>
            </BaseButton>
        </form>
    );

    // Parent flow
    const handleParentFlowAdditionalInfoInputChange = (event) => {
        event.preventDefault();
        setParentFlowFormData((prevFormData) => ({
            ...prevFormData,
            additional_info: event.target.value,
        }));
    };
    const handleParentFlowReferralSourceInputChange = (event) => {
        event.preventDefault();
        setParentFlowFormData((prevFormData) => ({
            ...prevFormData,
            referral_source: event.target.value,
        }));
    };
    const handleParentFlowSubmit = (event) => {
        event.preventDefault();
        const formattedFormData = {
            account_type: "parent",
            additional_info:
                "Additional info: " +
                parentFlowFormData.additional_info +
                "; Referral source: " +
                parentFlowFormData.referral_source,
        };
        handleAccountSetup(formattedFormData);
    };
    const parentFlow = (
        <form onSubmit={(event) => handleParentFlowSubmit(event)}>
            <label htmlFor="additionalInfoInput">What are you looking for in Athena?</label>
            <textarea
                type="text"
                id="additionalInfoInput"
                name="additionalInfo"
                placeholder="Optional"
                value={parentFlowFormData.additional_info}
                onChange={(e) => handleParentFlowAdditionalInfoInputChange(e)}
                className={styles.account_setup_textarea}
                maxLength={1000}
            ></textarea>
            <br />
            <label htmlFor="referralSourceInput">How did you hear about us?</label>
            <textarea
                type="text"
                id="referralSourceInput"
                name="referralSource"
                placeholder="My friend recommended this"
                value={parentFlowFormData.referral_source}
                onChange={(e) => handleParentFlowReferralSourceInputChange(e)}
                className={styles.account_setup_textarea}
                maxLength={1000}
            ></textarea>
            <BaseButton className={styles.setup_submit_button}>
                <img className={styles.action_button_icon} src={whiteAthenaV2} alt="white athena v2 icon" />
                <div className={styles.action_button_text}>Get Started</div>
            </BaseButton>
        </form>
    );

    // Other flow
    const handleOtherFlowInputChange = (event) => {
        event.preventDefault();
        setOtherFormData((prevFormData) => ({
            ...prevFormData,
            info: event.target.value,
        }));
    };
    const handleOtherFlowReferralSourceInputChange = (event) => {
        event.preventDefault();
        setOtherFormData((prevFormData) => ({
            ...prevFormData,
            referral_source: event.target.value,
        }));
    };
    const handleOtherFlowSubmit = (event) => {
        event.preventDefault();
        const formattedFormData = {
            account_type: "other",
            additional_info:
                "Additional info: " + otherFormData.info + "; Referral source: " + otherFormData.referral_source,
        };
        handleAccountSetup(formattedFormData);
    };
    const defaultFlow = (
        <form onSubmit={(event) => handleOtherFlowSubmit(event)}>
            <label htmlFor="otherInfoInput">Tell us more about yourself!</label>
            <textarea
                type="text"
                id="otherInfoInput"
                name="otherInfo"
                placeholder="What brought you here?"
                value={otherFormData.info}
                onChange={(e) => handleOtherFlowInputChange(e)}
                className={styles.account_setup_textarea}
                maxLength={1000}
            ></textarea>
            <br />
            <label htmlFor="referralSourceInput">How did you hear about us?</label>
            <textarea
                type="text"
                id="referralSourceInput"
                name="referralSource"
                placeholder="My clients were talking about Athena"
                value={otherFormData.referral_source}
                onChange={(e) => handleOtherFlowReferralSourceInputChange(e)}
                className={styles.account_setup_textarea}
                maxLength={1000}
            ></textarea>
            <BaseButton className={styles.setup_submit_button}>
                <img className={styles.action_button_icon} src={whiteAthenaV2} alt="white athena v2 icon" />
                <div className={styles.action_button_text}>Get Started</div>
            </BaseButton>
        </form>
    );

    return (
        <div className={styles.setup_flow_container}>
            {flow === "student" ? studentFlow : null}
            {flow === "parent" ? parentFlow : null}
            {flow === "other" ? defaultFlow : null}
        </div>
    );
}

export function AccountSetup() {
    const { userProfile, handleAccountSetup } = useAuthContext();
    const [selectedOption, setSelectedOption] = useState("");

    return (
        <div className={styles.background}>
            <div className={styles.setup_body}>
                <div className={styles.setup_title}>
                    <h1>Welcome to Athena, {userProfile.first_name}</h1>
                    <p>Let's get your account set up!</p>
                </div>
                <h3>I am a...</h3>
                <AccountTypeOptions selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                {selectedOption && (
                    <AccountSetupFlow
                        flow={selectedOption}
                        userProfile={userProfile}
                        handleAccountSetup={handleAccountSetup}
                    />
                )}
            </div>
        </div>
    );
}
