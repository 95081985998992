import BackgroundIcon from "../../imgs/background-icon.png";
import BlackAthenaV2 from "../../imgs/black-athena-v2.png";
import CommonAppIcon from "../../imgs/common-app-icon.png";
import creditIcon from "../../imgs/credit_icon.png";
import EducationalResourcesIcon from "../../imgs/educational-resources-icon.png";
import HelpIcon from "../../imgs/help-icon.png";
import LogoutIcon from "../../imgs/logout-icon.png";
import MailIcon from "../../imgs/mail-icon.png";
import MyApplicationsIcon from "../../imgs/my-applications-icon.png";
import PlaygroundIcon from "../../imgs/playground-icon.png";
import WhiteAthenaV2 from "../../imgs/white_athena_v2.png";
import ZapIcon from "../../imgs/zap-icon.png";
import { ReactComponent as AmbitiousStudentsIcon } from "../../imgs/ambitious-students-icon.svg";
import { ReactComponent as ManageStudentsIcon } from "../../imgs/manage-students-icon.svg";
import Dropdown from "../base/Dropdown";

import { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../styles/sidebar/Sidebar.module.css";

import BaseButton from "../base/BaseButton";

export default function Sidebar() {
    const {
        userProfile,
        handleUserLogout,
        registeredStudents,
        consultantCurrentStudent,
        handleAssignConsultantCurrentStudent,
        handleFetchConsultantCurrentStudent,
    } = useAuthContext();
    const navigate = useNavigate();
    const location = useLocation();

    const [currentTab, setCurrentTab] = useState(null);
    useEffect(() => {
        const pathSegments = location.pathname.split("/").filter(Boolean);
        if (pathSegments.length > 0) {
            setCurrentTab(pathSegments[0]);
        } else {
            setCurrentTab("");
        }
    }, [location]);

    const isCurrentTabActive = (matchingTabs) => {
        return matchingTabs.includes(currentTab);
    };

    const handleNavigateToMain = () => {
        navigate("/");
        window.scrollTo(0, 0);
    };
    const handleNavigateToMyApplications = () => {
        navigate("/applications");
        window.scrollTo(0, 0);
    };
    const handleNavigateToBackground = () => {
        navigate("/background");
        window.scrollTo(0, 0);
    };
    const handleNavigateToEducationalResources = () => {
        navigate("/educational-resources");
        window.scrollTo(0, 0);
    };
    const handleNavigateToCommonApp = () => {
        navigate("/common-app");
        window.scrollTo(0, 0);
    };
    const handleNavigateToPlayground = () => {
        navigate("/playground");
        window.scrollTo(0, 0);
    };
    const handleAmbitiousStudentsClick = () => {
        window.open("https://www.athenaco.ai/ambassador", "_blank", "noopener");
    };
    const handleContactUsClick = () => {
        window.open("https://www.athenaco.ai/about", "_blank", "noopener");
    };
    const handleNavigateToHelp = () => {
        window.open("https://www.athenaco.ai/blog/athena-introduction", "_blank", "noopener");
    };
    const handleNavigateToPricing = () => {
        navigate("/pricing");
        window.scrollTo(0, 0);
    };
    const handleManageStudentsClick = () => {
        navigate("/consultant/settings");
        window.scrollTo(0, 0);
    };

    const shouldShowCounselorDropdown =
        userProfile?.email === "ajnatarajan@gmail.com" ||
        // userProfile?.email === "eshao573@gmail.com" ||
        userProfile?.special_account_type === "consultant";

    const formattedStudents = registeredStudents.map((student) => {
        return { id: student.id, name: student.first_name + " " + student.last_name, email: student.email };
    });
    const formattedConsultantCurrentStudent =
        Object.keys(consultantCurrentStudent).length > 0
            ? {
                  id: consultantCurrentStudent?.id,
                  name: consultantCurrentStudent?.first_name + " " + consultantCurrentStudent?.last_name,
                  email: consultantCurrentStudent?.email,
              }
            : null;

    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const handleDropdownSetStudent = (student) => {
        handleAssignConsultantCurrentStudent(student);
    };

    // Need to manually refresh upon first load after login.
    useEffect(() => {
        handleFetchConsultantCurrentStudent();
    }, [handleFetchConsultantCurrentStudent]);

    const counselorDropdown = (
        <Dropdown
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            items={formattedStudents}
            selectedItem={formattedConsultantCurrentStudent}
            setSelectedItem={handleDropdownSetStudent}
            placeholder={"Select a student"}
            dropdownRef={dropdownRef}
            searchable={true}
        />
    );

    return (
        <div className={styles.sidebar_container_v2}>
            <div className={`${styles.sidebar_title}`} onClick={handleNavigateToMain}>
                <div className={styles.sidebar_title_logo_container}>
                    <img className={styles.sidebar_title_logo} src={WhiteAthenaV2} alt="athena black logo" width={30} />
                </div>
                <div className={styles.title_container}>
                    <div className={styles.sidebar_title_text}>Athena AI</div>
                    <div className={styles.sidebar_title_subtext}>Elevate your college apps</div>
                </div>
            </div>
            <div className={styles.sidebar_divider} />
            <div className={styles.scrollable_section}>
                <div className={styles.sidebar_navigation_section}>
                    <div className={styles.section_title}>Home</div>
                    <div
                        className={`${styles.action_button} ${styles.sidebar_hoverable_entity} ${
                            isCurrentTabActive(["", "applications"]) && styles.sidebar_active_hoverable_entity
                        }`}
                        onClick={handleNavigateToMyApplications}
                    >
                        <img
                            className={styles.action_button_icon}
                            src={MyApplicationsIcon}
                            alt="my applications icon"
                        />
                        <div className={styles.action_button_text}>My Applications</div>
                    </div>
                    <div
                        className={`${styles.action_button} ${styles.sidebar_hoverable_entity} ${
                            isCurrentTabActive(["background"]) && styles.sidebar_active_hoverable_entity
                        }`}
                        onClick={handleNavigateToBackground}
                    >
                        <img className={styles.action_button_icon} src={BackgroundIcon} alt="background icon" />
                        <div className={styles.action_button_text}>Background</div>
                    </div>
                    <div
                        className={`${styles.action_button} ${styles.sidebar_hoverable_entity} ${
                            isCurrentTabActive(["common-app"]) && styles.sidebar_active_hoverable_entity
                        }`}
                        onClick={handleNavigateToCommonApp}
                    >
                        <img className={styles.action_button_icon} src={CommonAppIcon} alt="common app icon" />
                        <div className={styles.action_button_text}>Common App</div>
                    </div>
                    <div
                        className={`${styles.action_button} ${styles.sidebar_hoverable_entity} ${
                            isCurrentTabActive(["playground"]) && styles.sidebar_active_hoverable_entity
                        }`}
                        onClick={handleNavigateToPlayground}
                    >
                        <img className={styles.action_button_icon} src={PlaygroundIcon} alt="playground icon" />
                        <div className={styles.action_button_text}>Playground</div>
                    </div>
                </div>
                <div className={styles.sidebar_divider} />
                <div className={styles.sidebar_navigation_section}>
                    <div className={styles.section_title}>Resources</div>
                    <div
                        className={`${styles.action_button} ${styles.sidebar_hoverable_entity} ${
                            isCurrentTabActive(["educational-resources"]) && styles.sidebar_active_hoverable_entity
                        }`}
                        onClick={handleNavigateToEducationalResources}
                    >
                        <img
                            className={styles.action_button_icon}
                            src={EducationalResourcesIcon}
                            alt="educational resources icon"
                        />
                        <div className={styles.action_button_text}>Education</div>
                    </div>
                </div>
                {!shouldShowCounselorDropdown && (<div
                    className={`${styles.action_button} ${styles.sidebar_hoverable_entity}`}
                    onClick={handleAmbitiousStudentsClick}
                >
                    <AmbitiousStudentsIcon className={styles.action_button_icon} />
                    <div className={styles.action_button_text}>Free Athena Pro?</div>
                </div>)}
                {shouldShowCounselorDropdown && (
                    <>
                        <div className={styles.sidebar_divider} />
                        <div className={styles.section_title}>For IECs</div>
                        <div className={styles.dropdown_section}>
                            <span className={styles.dropdown_section_title}>Current Student Profile</span>
                            {counselorDropdown}
                        </div>
                        <div
                            className={`${styles.action_button} ${styles.sidebar_hoverable_entity}`}
                            onClick={handleManageStudentsClick}
                        >
                            <ManageStudentsIcon className={styles.action_button_icon} />
                            <div className={styles.action_button_text}>Manage Students</div>
                        </div>
                    </>
                )}
                <div className={styles.flex_grow_div} />
                <div className={styles.sidebar_divider} />
                <div className={styles.sidebar_bottom_section}>
                    <div className={styles.section_title}>Support</div>
                    <div
                        className={`${styles.action_button} ${styles.sidebar_hoverable_entity}`}
                        onClick={handleContactUsClick}
                    >
                        <img className={styles.action_button_icon} src={MailIcon} alt="contact us icon" />
                        <div className={styles.action_button_text}>Contact Us</div>
                    </div>
                    <div
                        className={`${styles.action_button} ${styles.sidebar_hoverable_entity}`}
                        onClick={handleNavigateToHelp}
                    >
                        <img className={styles.action_button_icon} src={HelpIcon} alt="help icon" />
                        <div className={styles.action_button_text}>Help</div>
                    </div>
                    <div
                        className={`${styles.action_button} ${styles.sidebar_hoverable_entity}`}
                        onClick={() => handleUserLogout()}
                    >
                        <img className={styles.action_button_icon} src={LogoutIcon} alt="log out icon" />
                        <div className={styles.action_button_text}>Log Out</div>
                    </div>
                    <BaseButton className={styles.upgrade_button} onClick={handleNavigateToPricing}>
                        <img className={styles.action_button_icon} src={ZapIcon} alt="upgrade icon" />
                        <div className={styles.action_button_text}>Upgrade</div>
                    </BaseButton>
                    <ProfileSection />
                </div>
            </div>
        </div>
    );
}

function ProfileSection(props) {
    const { userProfile } = useAuthContext();
    const userProfileImgToDisplay = userProfile?.profile_image || BlackAthenaV2;
    const userNameToDisplay = userProfile?.first_name + " " + userProfile?.last_name;
    const creditToDisplay =
        userProfile?.credit && !isNaN(userProfile?.credit)
            ? parseFloat(userProfile?.credit).toLocaleString("en-US", {
                  style: "decimal",
                  maximumFractionDigits: 0,
              })
            : "";
    let membershipPlan = userProfile?.membership_plan
        ? userProfile?.membership_plan.charAt(0).toUpperCase() + userProfile?.membership_plan.slice(1).toLowerCase()
        : "";
    if (membershipPlan === "Pro" || membershipPlan === "Lite") {
        membershipPlan = "Athena " + membershipPlan;
    }
    const isConsultant = userProfile?.special_account_type === "consultant";

    return (
        <div className={styles.sidebar_profile_section}>
            <div className={styles.profile_card}>
                <img className={styles.profile_picture_v2} src={userProfileImgToDisplay} alt="google profile pic" />
                <div className={styles.profile_info}>
                    <div className={styles.profile_name}>{userNameToDisplay}</div>
                    {isConsultant ? (
                        <div className={styles.profile_membership}>
                            <div>Consultant</div>
                        </div>
                    ) : membershipPlan === "Starter" || membershipPlan === "Free" ? (
                        <div className={styles.profile_credits_remaining}>
                            <span>Report Cards:</span>
                            <img className={styles.credit_icon} src={creditIcon} alt="credit icon" />
                            <span>{creditToDisplay}</span>
                        </div>
                    ) : (
                        <div className={styles.profile_membership}>
                            <div>{membershipPlan}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
