import { useEffect, useRef, useState } from "react";
import Editor from "../editor/Editor";
import ApplicationSidebar from "./sidebar/ApplicationSidebar";
import styles from "../../styles/application/Application.module.css";
import { useApplicationContext } from "./ApplicationContext";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { initialConfig } from "../editor/Editor";

import ChevronDownIcon from "../../imgs/chevron-down-icon.png";
import ChevronLeftIcon from "../../imgs/chevron-left-icon.png";
import DoubleChevronLeftIcon from "../../imgs/double-chevron-left-icon.png";
import BaseButton from "../base/BaseButton";
import essayPrompts from "./essayPrompts";
import ReactMarkdown from "react-markdown";

import { useNavigate, useParams } from "react-router-dom";
import ToolbarPlugin from "../editor/ToolbarPlugin";

function DropdownItem(props) {
    const { onClick, prompt } = props;

    return (
        <div className={styles.dropdown_item} onClick={onClick}>
            <span className={styles.dropdown_item_title}>{prompt.title}</span>
            {prompt.tag && <div className={styles.dropdown_item_tag}>{prompt.tag}</div>}
        </div>
    );
}

function Dropdown(props) {
    const { onClick, dropdownRef, showDropdown } = props;
    const { prompts, selectedPrompt, setSelectedPrompt } = useApplicationContext();
    return (
        <div className={styles.dropdown_container} onClick={onClick} ref={dropdownRef}>
            <div className={styles.dropdown_preview}>
                <span>{selectedPrompt && selectedPrompt.title}</span>
                <img
                    className={`${styles.dropdown_arrow} ${showDropdown && styles.rotated_dropdown_arrow}`}
                    src={ChevronDownIcon}
                    alt="arrow"
                />
            </div>
            {/* TODO (Eugene): make this not hard-coded */}
            {showDropdown && (
                <div className={styles.dropdown_content}>
                    {prompts.map((prompt, index) => {
                        return <DropdownItem key={index} onClick={() => setSelectedPrompt(prompt)} prompt={prompt} />;
                    })}
                </div>
            )}
        </div>
    );
}

// ApplicationInitializer is a wrapper component that sets the application ID in the context for url-based applications
export function ApplicationInitializer() {
    const { id } = useParams();
    const { handleSetApplicationId } = useApplicationContext();

    useEffect(() => {
        handleSetApplicationId(id);
    }, [id]);

    return <CollegeApplication />;
}

export function CollegeApplication(props) {
    const [showDropdown, setShowDropdown] = useState(false);
    const {
        applicationInfo,
        essayId,
        prompts,
        selectedPrompt,
        showSidebar,
        toggleSidebar,
        applicationPageLoading,
        isEditorStateSaving,
    } = useApplicationContext();

    const navigate = useNavigate();

    const headerSection = () => {
        return (
            <>
                <div className={styles.dashboard_return} onClick={() => navigate("/applications")}>
                    <img
                        className={styles.dashboard_return_icon}
                        src={ChevronLeftIcon}
                        alt="return to application dashboard icon"
                    />
                    My Applications
                </div>
                <div className={styles.application_header}>
                    <img className={styles.application_header_logo} src={applicationInfo?.assets.logo} alt="logo" />
                    <div className={styles.application_header_info}>
                        <span className={styles.application_header_title}>{applicationInfo?.schoolName}</span>
                        <span className={styles.application_header_deadline}>
                            {applicationInfo?.admissionPlan
                                ?.split("_")
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ")}
                            {applicationInfo?.due_date && `: ${applicationInfo?.due_date}`}
                        </span>
                    </div>
                </div>
                <div className={styles.application_banner_text}>
                    {`Note: Double-check the ${applicationInfo?.schoolName} website to confirm essays you need to answer.`}
                </div>
            </>
        );
    };

    const dropdownRef = useRef(null);
    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const handleDropdownClick = () => setShowDropdown(!showDropdown);
    const promptSection = () => {
        return (
            <>
                <div className={styles.options_bar}>
                    <div className={styles.application_dropdown_container}>
                        <Dropdown onClick={handleDropdownClick} showDropdown={showDropdown} dropdownRef={dropdownRef} />
                    </div>
                    {isEditorStateSaving && <span className={styles.application_saving_text}>Saving...</span>}
                </div>
                <div className={styles.prompt} key={selectedPrompt?.id}>
                    <div className={styles.prompt_title}>Prompt:</div>
                    <ReactMarkdown className={styles.prompt_text}>{selectedPrompt?.text}</ReactMarkdown>
                </div>
            </>
        );
    };

    const editor = () => <Editor key={essayId} essayId={essayId} />;

    const sidebarSection = () => {
        const showSidebarButton = (
            <BaseButton onClick={() => toggleSidebar(true)} className={`${styles.button} ${styles.open_button}`}>
                <img
                    className={styles.button_icon}
                    src={DoubleChevronLeftIcon}
                    alt="button for opening athena sidebar"
                />
            </BaseButton>
        );

        return (
            <>
                <ApplicationSidebar />
                {showSidebar && <div className={styles.gray_background} onClick={() => toggleSidebar(false)}></div>}
                {!showSidebar && showSidebarButton}
            </>
        );
    };

    if (applicationPageLoading) {
        return <div className={styles.coming_soon}>Loading...</div>;
    }

    if (!applicationInfo) {
        return;
    }

    /* Brown University and Villanova University do not allow AI usage on college application essays */
    if (applicationInfo.schoolCode === "brown" || applicationInfo.schoolCode === "villanova") {
        return (
            <div className={styles.application}>
                <div className={styles.unreleased_prompts_container}>
                    <div className={styles.dashboard_return} onClick={() => navigate("/applications")}>
                        <img
                            className={styles.dashboard_return_icon}
                            src={ChevronLeftIcon}
                            alt="return to application dashboard icon"
                        />
                        My Applications
                    </div>
                    <p className={styles.unreleased_prompts_notice}>
                        {applicationInfo.schoolName} does not allow AI usage on college application essays 😔. We will
                        update you if university policies change!
                    </p>
                </div>
            </div>
        );
    }

    /* Show unreleased essay notice if 2025 prompts have yet to be released. */
    if (!(applicationInfo.schoolCode in essayPrompts)) {
        return (
            <div className={styles.application}>
                <div className={styles.unreleased_prompts_container}>
                    <div className={styles.dashboard_return} onClick={() => navigate("/applications")}>
                        <img
                            className={styles.dashboard_return_icon}
                            src={ChevronLeftIcon}
                            alt="return to application dashboard icon"
                        />
                        My Applications
                    </div>
                    <p className={styles.unreleased_prompts_notice}>
                        {applicationInfo.schoolName}'s 2025 application prompts have not yet been released. Check back
                        when they're released to get started!
                    </p>
                </div>
            </div>
        );
    }

    /* Let the user know if a college does not require supplemental essays */
    if (!prompts || prompts.length === 0) {
        return (
            <div className={styles.application}>
                <div className={styles.unreleased_prompts_container}>
                    <div className={styles.dashboard_return} onClick={() => navigate("/applications")}>
                        <img
                            className={styles.dashboard_return_icon}
                            src={ChevronLeftIcon}
                            alt="return to application dashboard icon"
                        />
                        My Applications
                    </div>
                    <p className={styles.unreleased_prompts_notice}>
                        {applicationInfo.schoolName} doesn't have any 2024-2025 supplemental essays. You're all set! If
                        you believe this is a mistake, please contact us at{" "}
                        <a href="mailto:support@athenaco.ai">support@athenaco.ai</a>.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <BaseApplication
            headerSection={headerSection()}
            promptSection={promptSection()}
            editor={editor()}
            sidebarSection={sidebarSection()}
        />
    );
}

export function PlaygroundApplication(props) {
    const [showDropdown, setShowDropdown] = useState(false);
    const { setShowEditor } = props;
    const {
        applicationInfo,
        essayId,
        selectedPrompt,
        toggleSidebar,
        showSidebar,
        applicationPageLoading,
        isEditorStateSaving,
    } = useApplicationContext();

    const headerSection = () => {
        return (
            <>
                <div className={styles.dashboard_return} onClick={() => setShowEditor(false)}>
                    <img
                        className={styles.dashboard_return_icon}
                        src={ChevronLeftIcon}
                        alt="return to playground icon"
                    />
                    My Custom Prompts
                </div>
                <div className={styles.application_header}>
                    <div className={styles.application_header_info}>
                        <span className={styles.application_header_title}>Playground</span>
                    </div>
                </div>
            </>
        );
    };

    const dropdownRef = useRef(null);
    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const handleDropdownClick = () => setShowDropdown(!showDropdown);
    const promptSection = () => {
        return (
            <>
                <div className={styles.options_bar}>
                    <div className={styles.application_dropdown_container}>
                        <Dropdown onClick={handleDropdownClick} showDropdown={showDropdown} dropdownRef={dropdownRef} />
                    </div>
                    {isEditorStateSaving && <span className={styles.application_saving_text}>Saving...</span>}
                </div>
                <div className={styles.prompt} key={selectedPrompt?.id}>
                    <div className={styles.prompt_title}>Prompt:</div>
                    <ReactMarkdown>{selectedPrompt?.text}</ReactMarkdown>
                </div>
            </>
        );
    };

    const editor = () => <Editor key={essayId} essayId={essayId} />;

    const sidebarSection = () => {
        const showSidebarButton = (
            <BaseButton onClick={() => toggleSidebar(true)} className={`${styles.button} ${styles.open_button}`}>
                <img
                    className={styles.button_icon}
                    src={DoubleChevronLeftIcon}
                    alt="button for opening athena sidebar"
                />
            </BaseButton>
        );

        return (
            <>
                <ApplicationSidebar />
                {showSidebar && <div className={styles.gray_background} onClick={() => toggleSidebar(false)}></div>}
                {!showSidebar && showSidebarButton}
            </>
        );
    };

    if (applicationPageLoading) {
        return <div className={styles.coming_soon}>Loading...</div>;
    }

    if (!applicationInfo) {
        return;
    }

    return (
        <BaseApplication
            headerSection={headerSection()}
            promptSection={promptSection()}
            editor={editor()}
            sidebarSection={sidebarSection()}
        />
    );
}

function BaseApplication(props) {
    const { headerSection, promptSection, editor, sidebarSection } = props;
    const { applicationInfo } = useApplicationContext();

    if (!applicationInfo) {
        return;
    }

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div className={styles.application_container}>
                <div className={styles.application_editor_container}>
                    <div className={styles.application}>
                        <div className={styles.application_details}>
                            {headerSection}
                            {promptSection}
                        </div>
                        <div className={styles.application_divider}></div>
                        {editor}
                    </div>
                    <ToolbarPlugin />
                </div>
                {sidebarSection}
            </div>
        </LexicalComposer>
    );
}
