import styles from "../../styles/consultant/ConsultantSettingsPage.module.css";
import BaseButton from "../base/BaseButton";
import { ReactComponent as CloseIcon } from "../../imgs/close-icon.svg";
import { useAuthContext } from "../auth/AuthContext";
import Dropdown from "../base/Dropdown";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

const formatTimestamp = (timestamp) => {
    if (!timestamp) return;
    const date = new Date(timestamp);
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
    };
    return date.toLocaleString({}, options);
};

const toUpperCase = (value) => {
    if (!value) return;
    if (typeof value !== "string") return;

    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

function Card(props) {
    const { title, body } = props;

    return (
        <div key={title} className={styles.card}>
            <div className={styles.card_title}>{title}</div>
            <div className={styles.card_body}>{body}</div>
        </div>
    );
}

function ProfileBody(props) {
    const { userProfile } = useAuthContext();
    const { isLoading, organization } = props;

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className={styles.card_section}>
                <h3 className={styles.list_title}>Profile</h3>
                <ul>
                    <li>Name: {userProfile?.first_name + " " + userProfile?.last_name}</li>
                    <li>Email: {userProfile?.email}</li>
                    <li>Organization: {organization?.name ?? "No organization"} </li>
                </ul>
            </div>
            <div className={styles.card_section}>
                <h3 className={styles.list_title}>Organization</h3>
                <ul>
                    <li>Name: {organization?.name}</li>
                    <li>
                        Available Licenses:{" "}
                        {organization && Object.keys(organization).length > 0 ? (
                            <ul>
                                {Object.keys(organization?.licenses ?? {}).map((key, index) => (
                                    <li key={index}>
                                        {toUpperCase(key)}: {organization?.licenses[key]}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            "No licenses available"
                        )}
                    </li>
                    <li>License End Date: {formatTimestamp(organization?.license_end_date)} </li>
                </ul>
            </div>
        </>
    );
}

function RegisteredStudentsBody(props) {
    const { isLoading, registeredStudents } = props;

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <table>
            <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Membership Plan</th>
                    <th>Membership End Date</th>
                </tr>
            </thead>
            <tbody>
                {registeredStudents?.map((user, index) => {
                    return (
                        <tr key={index}>
                            <td>{user.first_name}</td>
                            <td>{user.last_name}</td>
                            <td>{user.membership_plan}</td>
                            <td>{formatTimestamp(user.membership_end_date)}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

function AddStudentsBody(props) {
    const { setAddStudentsFormattedData, setShowSubmissionConfirmationModal } = props;

    const handleAddStudentClick = (event) => {
        event.preventDefault();
        setShowSubmissionConfirmationModal(true);
        setAddStudentsFormattedData({
            first_name: event.target.firstName.value,
            last_name: event.target.lastName.value,
            membership_plan: selectedPlan.value,
        });
    };

    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const dropdownRef = useRef(null);
    const membershipPlanOptions = [
        // { name: "Starter", value: "starter" },
        { name: "Lite", value: "lite" },
        { name: "Pro", value: "pro" },
    ];
    const membershipPlanDropdown = (
        <Dropdown
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            items={membershipPlanOptions}
            dropdownRef={dropdownRef}
            selectedItem={selectedPlan}
            setSelectedItem={setSelectedPlan}
            placeholder={"Select Membership Plan"}
        />
    );
    const shouldDisableAddStudentButton = selectedPlan === null;

    return (
        <>
            <div className={styles.add_students_container}>
                <form className={styles.add_students_form} onSubmit={(event) => handleAddStudentClick(event)}>
                    <div className={styles.form_section}>
                        <label htmlFor="firstName">First Name</label>
                        <input id="firstName" type="text" placeholder="John" required />
                    </div>
                    <div className={styles.form_section}>
                        <label htmlFor="lastName">Last Name</label>
                        <input id="lastName" type="text" placeholder="Smith" />
                    </div>
                    {/* TODO (Eugene): Need to do some jank overflow for the dropdown only */}
                    <div className={styles.form_section}>
                        <label htmlFor="selectMembershipPlan">Membership Plan</label>
                        {membershipPlanDropdown}
                    </div>
                    <BaseButton
                        className={`${styles.add_students_button} ${
                            shouldDisableAddStudentButton && styles.disabled_add_student_button
                        }`}
                        disabled={shouldDisableAddStudentButton}
                    >
                        {/* {isSubmitting ? "Loading" : "Add Student"} */}
                        Add Student
                    </BaseButton>
                </form>
            </div>
        </>
    );
}

function ConsultantHelpBanner() {
    return (
        <div className={styles.banner}>
            If you need help, please reach out to{" "}
            <a className={styles.banner_mail_link} href="mailto:support@athenaco.ai">
                support@athenaco.ai
            </a>
        </div>
    );
}

function SubmissionConfirmationModal(props) {
    const { addStudentsFormattedData, handleConfirmSubmission, organization, setShowSubmissionConfirmationModal } =
        props;
    const remainingLicensesString =
        ((organization?.licenses && organization?.licenses[addStudentsFormattedData?.membership_plan]) ?? "N/A") +
        " " +
        addStudentsFormattedData.membership_plan +
        " " +
        (organization?.licenses && organization?.licenses[addStudentsFormattedData?.membership_plan] === 1
            ? "license"
            : "licenses") +
        " ";

    return (
        <div className={styles.confirmation_modal}>
            <div className={styles.modal_content}>
                <div className={styles.modal_header}>
                    <h2 className={styles.modal_title}>Add Student Confirmation</h2>
                    <BaseButton
                        className={styles.close_button}
                        onClick={() => setShowSubmissionConfirmationModal(false)}
                    >
                        <CloseIcon />
                    </BaseButton>
                </div>
                <p className={styles.modal_warning_text}>
                    You are registering{" "}
                    <span className={styles.modal_warning_text_emphasis}>
                        {addStudentsFormattedData.first_name} {addStudentsFormattedData.last_name}
                    </span>{" "}
                    with a{" "}
                    <span className={styles.modal_warning_text_emphasis}>
                        {addStudentsFormattedData.membership_plan}{" "}
                    </span>
                    license. You currently have{" "}
                    <span className={styles.modal_warning_text_emphasis}>{remainingLicensesString}</span>
                    remaining. Would you like to continue?
                </p>
                <div className={styles.modal_footer}>
                    <BaseButton
                        className={styles.cancel_button}
                        onClick={() => setShowSubmissionConfirmationModal(false)}
                    >
                        Cancel
                    </BaseButton>
                    <BaseButton className={styles.modal_add_student_button} onClick={handleConfirmSubmission}>
                        Add Student
                    </BaseButton>
                </div>
            </div>
            <div className={styles.gray_background} />
        </div>
    );
}

export default function ConsultantSettingsPage(props) {
    const {
        handleFetchConsultantCurrentStudent,
        handleFetchConsultantOrganizationData,
        handleFetchConsultantRegisteredStudents,
        handleRegisterConsultantStudent,
        registeredStudents,
        organizationData,
    } = useAuthContext();

    // Data Refresh
    const [refreshKey, setRefreshKey] = useState(0);

    // Profile Card
    const [isProfileCardLoading, setIsProfileCardLoading] = useState(true);

    // Registered Students Card
    const [isRegisteredStudentsCardLoading, setIsRegisteredStudentsCardLoading] = useState(true);

    // Add Student Card
    const [showSubmissionConfirmationModal, setShowSubmissionConfirmationModal] = useState(false);
    const [addStudentsFormattedData, setAddStudentsFormattedData] = useState({});

    useEffect(() => {
        handleFetchConsultantOrganizationData().finally(() => setIsProfileCardLoading(false));
        handleFetchConsultantRegisteredStudents().finally(() => setIsRegisteredStudentsCardLoading(false));
    }, [handleFetchConsultantOrganizationData, handleFetchConsultantRegisteredStudents, refreshKey]);

    const handleConfirmSubmission = () => {
        handleRegisterConsultantStudent(addStudentsFormattedData)
            .then((_) => {
                toast.success("Student added successfully");
                setShowSubmissionConfirmationModal(false);
                setRefreshKey((prevKey) => prevKey + 1);

                // Refresh to show defaulting if this is the consultant's first student
                handleFetchConsultantCurrentStudent();
            })
            .catch((error) => toast.error(error?.response?.data?.error));
    };

    return (
        <div className={styles.consultant_settings_page_container}>
            <div className={styles.settings_description}>
                <h1 className={styles.title}>Consultant Settings</h1>
            </div>
            <div className={styles.settings_content}>
                <Card
                    title={"Basic Information"}
                    body={<ProfileBody organization={organizationData} isLoading={isProfileCardLoading} />}
                />
                <Card
                    title={"Registered Students"}
                    body={
                        <RegisteredStudentsBody
                            isLoading={isRegisteredStudentsCardLoading}
                            registeredStudents={registeredStudents}
                        />
                    }
                />
                <Card
                    title={"Add Students"}
                    body={
                        <AddStudentsBody
                            setShowSubmissionConfirmationModal={setShowSubmissionConfirmationModal}
                            setAddStudentsFormattedData={setAddStudentsFormattedData}
                        />
                    }
                />
                <ConsultantHelpBanner />
            </div>
            {showSubmissionConfirmationModal && (
                <SubmissionConfirmationModal
                    addStudentsFormattedData={addStudentsFormattedData}
                    handleConfirmSubmission={handleConfirmSubmission}
                    setShowSubmissionConfirmationModal={setShowSubmissionConfirmationModal}
                    organization={organizationData}
                />
            )}
        </div>
    );
}
