import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import {
    assignConsultantCurrentStudent,
    checkAuthentication,
    checkPasswordResetLink,
    cleverLogin,
    deleteAccountLinkingRequest,
    fetchAdminDashboard,
    fetchAdminDashboardLast50Users,
    fetchConsultantCurrentStudent,
    fetchConsultantOrganizationData,
    fetchConsultantRegisteredStudents,
    fetchIPGeolocation,
    getUserProfile,
    linkAccount,
    processAccountSetup,
    registerConsultantStudent,
    sendAccountLinkingRequest,
    sendPasswordResetEmail,
    unlinkAccount,
    userGoogleLogin,
    userGoogleSignupViaAccessToken,
    userLogin,
    userLogOut,
    userResetPassword,
    userSignup,
} from "../../api/apiCalls";
import { useUserContext } from "./UserContext";
import { Logtail } from "@logtail/browser";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_ATHENA_FRONTEND_SOURCE_TOKEN);

const AuthContext = createContext();
const MARKETING_SITE_URL = process.env.REACT_APP_MARKETING_SITE_URL;

const canSeeConsultantUI = (user) => {
    return (
        user.special_account_type === "consultant" || // Temporarily hide consultant UI from eugene and ajay
        // user.email === "eshao573@gmail.com" ||
        // user.email === "ajnatarajan@gmail.com" ||
        user.email === "support@athenaco.ai"
    );
};

export function useAuthContext() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const { userProfile, setUserProfile, frontendActiveProfile, setFrontendActiveProfile, isLoggedIn, setIsLoggedIn } =
        useUserContext();

    const navigate = useNavigate();

    function handleGoogleLogin(credentialResponse, navigateOnLoginCompletion) {
        userGoogleLogin(credentialResponse.credential)
            .then((response) => {
                const user = response.data.user;
                setUserProfile(user);
                setFrontendActiveProfile(user);
                setIsLoggedIn(true);
                navigate(navigateOnLoginCompletion);
            })
            .catch((error) => {
                toast.error(`${error?.response?.data?.error}`);
                navigate("/signup");
            });
    }

    function handleUserLogin(formData, navigateOnLoginCompletion) {
        return userLogin(formData)
            .then((response) => {
                const user = response.data.user;
                setUserProfile(user);
                setFrontendActiveProfile(user);
                setIsLoggedIn(true);
                navigate(navigateOnLoginCompletion);
            })
            .catch((error) => {
                throw error;
            });
    }

    function handleUserLogout(logoutRedirectUrl = MARKETING_SITE_URL) {
        userLogOut()
            .then((response) => {
                setUserProfile({});
                setFrontendActiveProfile({});
                setIsLoggedIn(false);
                window.location.replace(logoutRedirectUrl);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error);
            });
    }

    async function handleGoogleSignupViaAccessToken(tokenResponse, navigateOnSignupCompletion) {
        let country = "";
        try {
            const response = await fetchIPGeolocation();
            country = response?.data?.country;
        } catch (error) {
            logtail.info(`Error fetching IP Geolocation data`, {
                additionalInfo: {
                    message: error?.response?.data?.error,
                },
            });
        }

        const formattedData = {
            access_token: tokenResponse.access_token,
            country: country,
        };
        userGoogleSignupViaAccessToken(formattedData)
            .then((response) => {
                const user = response.data.user;
                setUserProfile(user);
                setFrontendActiveProfile(user);
                setIsLoggedIn(true);
                navigate(navigateOnSignupCompletion);
            })
            .catch((error) => {
                logtail.warn(`Error during google user signup`, {
                    additionalInfo: {
                        message: error?.response?.data?.error,
                        tokenErrorDescription: tokenResponse?.error_description ?? "",
                    },
                });
                toast.error(error?.response?.data?.error);
            });
    }

    function handlePasswordResetLinkCheck(userId, token) {
        return checkPasswordResetLink(userId, token);
    }

    function handleResetPassword(formData) {
        return userResetPassword(formData)
            .then((_) => {
                // TODO: Replace with toast notifications
                alert("Password reset successfully.");
                navigate("/login");
            })
            .catch((error) => {
                throw error;
            });
    }

    function handleSendPasswordResetEmail(formData) {
        return sendPasswordResetEmail(formData)
            .then((_) => {})
            .catch((error) => {
                throw error;
            });
    }

    async function handleUserSignup(formData, navigateOnSignupCompletion) {
        let country = "";
        try {
            const response = await fetchIPGeolocation();
            country = response?.data?.country;
        } catch (error) {
            logtail.info(`Error fetching IP Geolocation data`, {
                additionalInfo: {
                    message: error?.response?.data?.error,
                },
            });
        }

        formData["country"] = country;
        return userSignup(formData)
            .then((response) => {
                const user = response.data.user;
                setUserProfile(user);
                setFrontendActiveProfile(user);
                setIsLoggedIn(true);
                navigate(navigateOnSignupCompletion);
            })
            .catch((error) => {
                logtail.warn(`Error during user signup ${formData?.email}`, {
                    additionalInfo: {
                        message: error?.response?.data?.error,
                    },
                });
                throw error;
            });
    }

    // This function is run on page load to check if the user is authenticated and set the state accordingly
    const handleAuthenticationCheck = useCallback(() => {
        checkAuthentication()
            .then((response) => {
                const loggedIn = response.data.is_authenticated;
                setIsLoggedIn(loggedIn);
                if (loggedIn) {
                    getUserProfile().then((response) => {
                        const user = response.data.user;
                        setUserProfile(user);
                        setFrontendActiveProfile(user); // default to consultant's profile
                        if (canSeeConsultantUI(user)) {
                            fetchConsultantCurrentStudent()
                                .then((response) => {
                                    const studentProfile = response.data.student;
                                    if (!studentProfile.id) {
                                        return;
                                    }
                                    setFrontendActiveProfile(studentProfile);
                                    setConsultantCurrentStudent(studentProfile);
                                })
                                .catch((error) => {
                                    toast.error(error?.response?.data?.error);
                                    setFrontendActiveProfile(user); // default to consultant's profile
                                });
                        }
                    });
                }
            })
            .catch((_) => {});
    }, [setFrontendActiveProfile, setIsLoggedIn, setUserProfile]);

    function handleAccountSetup(data) {
        processAccountSetup(data)
            .then((_) => navigate("/"))
            .catch((_) => {});
    }

    function handleAccountLinkingRequest(data) {
        // We can do a simple return sendAccountLinkingRequest(data) here
        return sendAccountLinkingRequest(data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    function handleCleverLogin(formData) {
        return cleverLogin(formData).then((response) => {
            const user = response.data.user;
            setUserProfile(user);
            setFrontendActiveProfile(user);
            setIsLoggedIn(true);
            navigate("/");
        });
    }

    // DEPRECATED
    function handleAccountUnlinkingRequest(formData) {
        return unlinkAccount(formData)
            .then((_) => console.log("unlinking request sent successfully!"))
            .catch((_) => console.error("account unlinking failed"));
    }

    function handleConfirmIncomingLinkingRequest(formData) {
        return linkAccount(formData)
            .then((_) => console.log("linking account confirmed successfully!"))
            .catch((_) => console.error("account linking failed"));
    }

    function handleDeleteIncomingLinkingRequest(formData) {
        return deleteAccountLinkingRequest(formData)
            .then((_) => console.log("linking account deleted successfully!"))
            .catch((_) => console.error("account linking deletion failed"));
    }

    // Admin dashboard stuff
    const handleFetchAdminDashboard = useCallback(() => fetchAdminDashboard(), []);
    const handleFetchAdminDashboardLast50Users = useCallback((page) => fetchAdminDashboardLast50Users(page), []);

    // Consultant UI
    const [registeredStudents, setRegisteredStudents] = useState([]);
    const [organizationData, setOrganizationData] = useState({});
    const [consultantCurrentStudent, setConsultantCurrentStudent] = useState({});

    const handleFetchConsultantOrganizationData = useCallback(async () => {
        if (!isLoggedIn || !canSeeConsultantUI(userProfile)) {
            return;
        }
        return fetchConsultantOrganizationData()
            .then((response) => {
                setOrganizationData(response.data.organization)
            })
            .catch((error) => toast.error(error?.response?.data?.error));
    }, [isLoggedIn, userProfile]);
    const handleFetchConsultantRegisteredStudents = useCallback(async () => {
        if (!isLoggedIn || !canSeeConsultantUI(userProfile)) {
            return;
        }

        // organizationData is usually not loaded yet, so this was the cleanest option I could find
        let is_admin_sees_all_students = null;
        
        await fetchConsultantOrganizationData()
            .then((response) => {
                is_admin_sees_all_students = response.data.organization.is_admin_sees_all_students
            })
            .catch((error) => toast.error(error?.response?.data?.error));

        return fetchConsultantRegisteredStudents(is_admin_sees_all_students)
            .then((response) => setRegisteredStudents(response.data.students))
            .catch((error) => toast.error(error?.response?.data?.error));
    }, [isLoggedIn, userProfile]);

    
    const handleRegisterConsultantStudent = useCallback((formData) => registerConsultantStudent(formData), []);
    const handleAssignConsultantCurrentStudent = useCallback(
        async (student) => {
            const formattedData = {
                student_id: student.id,
            };
            assignConsultantCurrentStudent(formattedData)
                .then((response) => {
                    setConsultantCurrentStudent(response.data.student);
                    navigate("/");
                })
                .catch((error) => toast.error(error?.response?.data?.error));
            return;
        },
        [navigate]
    );
    const handleFetchConsultantCurrentStudent = useCallback(async () => {
        if (!isLoggedIn || !canSeeConsultantUI(userProfile)) {
            return;
        }
        return fetchConsultantCurrentStudent()
            .then((response) => setConsultantCurrentStudent(response.data.student))
            .catch((error) => toast.error(error?.response?.data?.error));
    }, [isLoggedIn, userProfile]);

    useEffect(() => {
        handleFetchConsultantOrganizationData();
        handleFetchConsultantRegisteredStudents();
        handleFetchConsultantCurrentStudent();
    }, [
        handleFetchConsultantCurrentStudent,
        handleFetchConsultantOrganizationData,
        handleFetchConsultantRegisteredStudents,
    ]);

    // Update frontendActiveProfile whenever userProfile changes
    useEffect(() => {
        if (isLoggedIn && canSeeConsultantUI(userProfile)) {
            consultantCurrentStudent.id
                ? setFrontendActiveProfile(consultantCurrentStudent)
                : setFrontendActiveProfile(userProfile); // default profile to consultant's profile if consultant has no students
        }
    }, [isLoggedIn, userProfile, consultantCurrentStudent, setFrontendActiveProfile]);

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                userProfile,
                frontendActiveProfile,
                handleAccountLinkingRequest,
                handleAccountUnlinkingRequest,
                handleConfirmIncomingLinkingRequest,
                handleDeleteIncomingLinkingRequest,
                handleUserLogin,
                handleUserLogout,
                handleFetchAdminDashboard,
                handleFetchAdminDashboardLast50Users,
                handleGoogleLogin,
                handleGoogleSignupViaAccessToken,
                handlePasswordResetLinkCheck,
                handleResetPassword,
                handleSendPasswordResetEmail,
                handleUserSignup,
                handleAuthenticationCheck,
                handleAccountSetup,
                handleCleverLogin,
                // Consultant UI
                handleFetchConsultantCurrentStudent,
                handleFetchConsultantOrganizationData,
                handleFetchConsultantRegisteredStudents,
                handleRegisterConsultantStudent,
                handleAssignConsultantCurrentStudent,
                consultantCurrentStudent,
                organizationData,
                registeredStudents,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
