/*
    This file contains the prompts for all applications we support.

    The keys of each object are the school codes for each university we support.

    Each prompt is an object with the following properties:
    - id: the id of the prompt (<YEAR>_<SCHOOL>_<PROMPT_NUMBER>)
    - title: the title of the prompt
    - text: the text of the prompt
    - maxWordCount: the maximum word count for the prompt
    - supplementalEssayCategory (optional): the category of the supplemental essay
    - tag (optional): an optional tag for the prompt
*/

const essayPrompts = {
    /* Common app */
    common_app: {
        prompts: [
            {
                id: "2024_common_app_1",
                title: "Prompt 1",
                text: "Some students have a background, identity, interest, or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your story. (650 words or fewer)",
                maxWordCount: 650,
                tag: "Optional",
            },
            {
                id: "2024_common_app_2",
                title: "Prompt 2",
                text: "The lessons we take from obstacles we encounter can be fundamental to later success. Recount a time when you faced a challenge, setback, or failure. How did it affect you, and what did you learn from the experience? (650 words or fewer)",
                maxWordCount: 650,
                tag: "Optional",
            },
            {
                id: "2024_common_app_3",
                title: "Prompt 3",
                text: "Reflect on a time when you questioned or challenged a belief or idea. What prompted your thinking? What was the outcome? (650 words or fewer)",
                maxWordCount: 650,
                tag: "Optional",
            },
            {
                id: "2024_common_app_4",
                title: "Prompt 4",
                text: "Reflect on something that someone has done for you that has made you happy or thankful in a surprising way. How has this gratitude affected or motivated you? (650 words or fewer)",
                maxWordCount: 650,
                tag: "Optional",
            },
            {
                id: "2024_common_app_5",
                title: "Prompt 5",
                text: "Discuss an accomplishment, event, or realization that sparked a period of personal growth and a new understanding of yourself or others. (650 words or fewer)",
                maxWordCount: 650,
                tag: "Optional",
            },
            {
                id: "2024_common_app_6",
                title: "Prompt 6",
                text: "Describe a topic, idea, or concept you find so engaging that it makes you lose all track of time. Why does it captivate you? What or who do you turn to when you want to learn more? (650 words or fewer)",
                maxWordCount: 650,
                tag: "Optional",
            },
            {
                id: "2024_common_app_7",
                title: "Prompt 7",
                text: "Share an essay on any topic of your choice. It can be one you've already written, one that responds to a different prompt, or one of your own design. (650 words or fewer)",
                maxWordCount: 650,
                tag: "Optional",
            },
        ],
    },

    /* NEW PROMPTS */
    princeton: {
        prompts: [
            {
                id: "2024_princeton_essay_option_1_(a.b._+_undecided)",
                title: "Essay Option 1 (A.B. + Undecided)",
                text: "As a research institution that also prides itself on its liberal arts curriculum, Princeton allows students to explore areas across the humanities and the arts, the natural sciences, and the social sciences. What academic areas most pique your curiosity, and how do the programs offered at Princeton suit your particular interests? (Please respond in 250 words or fewer.)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_princeton_essay_option_2_(b.s.e.)",
                title: "Essay Option 2 (B.S.E.)",
                text: "Please describe why you are interested in studying engineering at Princeton. Include any of your experiences in or exposure to engineering, and how you think the programs offered at the University suit your particular interests. (Please respond in 250 words or fewer.)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_princeton_your_voice_-_essay_1",
                title: "Your Voice - Essay 1",
                text: "Princeton values community and encourages students, faculty, staff and leadership to engage in respectful conversations that can expand their perspectives and challenge their ideas and beliefs. As a prospective member of this community, reflect on how your lived experiences will impact the conversations you will have in the classroom, the dining hall or other campus spaces. What lessons have you learned in life thus far? What will your classmates learn from you? In short, how has your lived experience shaped you?  (Please respond in 500 words or fewer.)",
                maxWordCount: 500,
            },
            {
                id: "2024_princeton_your_voice_-_essay_2",
                title: "Your Voice - Essay 2",
                text: "Princeton has a longstanding commitment to understanding our responsibility to society through service and civic engagement. How does your own story intersect with these ideals? (Please respond in 250 words or fewer.)",
                maxWordCount: 250,
            },
            {
                id: "2024_princeton_tiny_prompt_1",
                title: "Tiny Prompt 1",
                text: "What is a new skill you would like to learn in college? (50 words or fewer)",
                maxWordCount: 50,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_princeton_tiny_prompt_2",
                title: "Tiny Prompt 2",
                text: "What brings you joy? (50 words or fewer)",
                maxWordCount: 50,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_princeton_tiny_prompt_3",
                title: "Tiny Prompt 3",
                text: "What song represents the soundtrack of your life at this moment? (50 words or fewer)",
                maxWordCount: 50,
                supplementalEssayCategory: "Tiny Prompt",
            },
        ],
    },
    mit: {
        prompts: [
            {
                id: "2024_mit_short_answer_1",
                title: "Short Answer 1",
                text: "Tell us more about why this field of study at MIT appeals to you. (100-word limit)",
                maxWordCount: 100,
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_mit_short_answer_2",
                title: "Short Answer 2",
                text: "We know you lead a busy life, full of activities, many of which are required of you. Tell us about something you do simply for the pleasure of it. (150-word limit)",
                maxWordCount: 150,
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_mit_short_response_1",
                title: "Short Response 1",
                text: "While some reach their goals following well-trodden paths, others blaze their own trails achieving the unexpected. In what ways have you done something different than what was expected in your educational journey? (approximately 200 words)",
                maxWordCount: 225,
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_mit_short_response_2",
                title: "Short Response 2",
                text: "MIT brings people with diverse backgrounds together to collaborate, from tackling the world's biggest challenges to lending a helping hand. Describe one way you have collaborated with others to learn from them, with them, or contribute to your community together. (approximately 200 words)",
                maxWordCount: 225,
                supplementalEssayCategory: "Campus Contribution",
            },
            {
                id: "2024_mit_short_response_3",
                title: "Short Response 3",
                text: "How did you manage a situation or challenge that you didn't expect? What did you learn from it? (approximately 200 words)",
                maxWordCount: 225,
                supplementalEssayCategory: "Life Experience",
            },
        ],
    },
    harvard: {
        prompts: [
            {
                id: "2024_harvard_question_1",
                title: "Short Answer 1",
                text: "Harvard has long recognized the importance of enrolling a diverse student body. How will the life experiences that shape who you are today enable you to contribute to Harvard? (about 100 words)",
                maxWordCount: 150,
            },
            {
                id: "2024_harvard_question_2",
                title: "Short Answer 2",
                text: "Describe a time when you strongly disagreed with someone about an idea or issue. How did you communicate or engage with this person? What did you learn from this experience? (about 100 words)",
                maxWordCount: 150,
            },
            {
                id: "2024_harvard_question_3",
                title: "Short Answer 3",
                text: "Briefly describe any of your extracurricular activities, employment experience, travel, or family responsibilities that have shaped who you are. (about 100 words)",
                maxWordCount: 150,
            },
            {
                id: "2024_harvard_question_4",
                title: "Short Answer 4",
                text: "How do you hope to use your Harvard education in the future? (about 100 words)",
                maxWordCount: 150,
            },
            {
                id: "2024_harvard_question_5",
                title: "Short Answer 5",
                text: "Top 3 things your roommates might like to know about you. (about 100 words)",
                maxWordCount: 150,
            },
        ],
    },
    stanford: {
        prompts: [
            {
                id: "2024_stanford_question_1",
                title: "Short Essay 1",
                text: "The Stanford community is deeply curious and driven to learn in and out of the classroom. Reflect on an idea or experience that makes you genuinely excited about learning. (100 to 250 words)",
                maxWordCount: 250,
            },
            {
                id: "2024_stanford_question_2",
                title: "Short Essay 2",
                text: "Virtually all of Stanford's undergraduates live on campus. Write a note to your future roommate that reveals something about you or that will help your roommate — and us — know you better. (100 to 250 words)",
                maxWordCount: 250,
            },
            {
                id: "2024_stanford_question_3",
                title: "Short Essay 3",
                text: "Please describe what aspects of your life experiences, interests and character would help you make a distinctive contribution as an undergraduate to Stanford University. (100 to 250 words)",
                maxWordCount: 250,
            },
            {
                id: "2024_stanford_question_4",
                title: "Short Answer 1",
                text: "What is the most significant challenge that society faces today?",
                maxWordCount: 50,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_stanford_question_5",
                title: "Short Answer 2",
                text: "How did you spend your last two summers?",
                maxWordCount: 50,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_stanford_question_6",
                title: "Short Answer 3",
                text: "What historical moment or event do you wish you could have witnessed?",
                maxWordCount: 50,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_stanford_question_7",
                title: "Short Answer 4",
                text: "Briefly elaborate on one of your extracurricular activities, a job you hold, or responsibilities you have for your family.",
                maxWordCount: 50,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_stanford_question_8",
                title: "Short Answer 5",
                text: "List five things that are important to you.",
                maxWordCount: 50,
                supplementalEssayCategory: "Tiny Prompt",
            },
        ],
    },
    yale: {
        prompts: [
            {
                id: "2024_yale_short_answer_1",
                title: "Short Answer 1",
                text: "Tell us about a topic or idea that excites you and is related to one or more academic areas you selected above. Why are you drawn to it? (200 words or fewer)",
                maxWordCount: 200,
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_yale_short_answer_2",
                title: "Short Answer 2",
                text: "What is it about Yale that has led you to apply? (125 words or fewer)",
                maxWordCount: 125,
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_yale_tiny_prompt_1",
                title: "Short Take 1",
                text: "What inspires you? (200 characters or fewer)",
                maxWordCount: 35,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_yale_tiny_prompt_2",
                title: "Short Take 2",
                text: "If you could teach any college course, write a book, or create an original piece of art of any kind, what would it be? (200 characters or fewer)",
                maxWordCount: 35,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_yale_tiny_prompt_3",
                title: "Short Take 3",
                text: "Other than a family member, who is someone who has had a significant influence on you? What has been the impact of their influence? (200 characters or fewer)",
                maxWordCount: 35,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_yale_tiny_prompt_4",
                title: "Short Take 4",
                text: "What is something about you that is not included anywhere else in your application? (200 characters or fewer)",
                maxWordCount: 35,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_yale_essay_option_1",
                title: "Essay Option 1",
                text: "Reflect on a time you discussed an issue important to you with someone holding an opposing view. Why did you find the experience meaningful? (400 words or fewer)",
                maxWordCount: 400,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_yale_essay_option_2",
                title: "Essay Option 2",
                text: "Reflect on your membership in a community to which you feel connected. Why is this community meaningful to you? You may define community however you like. (400 words or fewer)",
                maxWordCount: 400,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_yale_essay_option_3",
                title: "Essay Option 3",
                text: "Reflect on an element of your personal experience that you feel will enrich your college. How has it shaped you? (400 words or fewer)",
                maxWordCount: 400,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
        ],
    },
    upenn: {
        prompts: [
            {
                id: "2024_upenn_question_1",
                title: "Short Answer 1",
                text: "Write a short thank-you note to someone you have not yet thanked and would like to acknowledge. (We encourage you to share this note with that person, if possible, and reflect on the experience!) (150-200 words, only required for first-year applicants)",
                maxWordCount: 200,
            },
            {
                id: "2024_upenn_question_2",
                title: "Short Answer 2",
                text: "How will you explore community at Penn? Consider how Penn will help shape your perspective, and how your experiences and perspective will help shape Penn. (150-200 words)",
                maxWordCount: 200,
            },
            {
                id: "2024_upenn_college_specific_-_school_of_nursing",
                title: "College Specific - School of Nursing",
                text: "Penn Nursing intends to meet the health needs of a global, multicultural world by preparing its students to impact healthcare through advancing science. How will you contribute to our mission of promoting equity in healthcare and how will Penn Nursing contribute to your future nursing goals? (150-200 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_upenn_college_specific_-_college_of_arts_and_sciences",
                title: "College Specific - College of Arts and Sciences",
                text: "The flexible structure of The College of Arts and Sciences' curriculum is designed to inspire exploration, foster connections, and help you create a path of study through general education courses and a major. What are you curious about and how would you take advantage of opportunities in the arts and sciences?\u202f(150-200 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_upenn_college_specific_-_the_wharton_school",
                title: "College Specific - The Wharton School",
                text: "Wharton prepares its students to make an impact by applying business methods and economic theory to real-world problems, including economic, political, and social issues.\u202f Please reflect on a current issue of importance to you and share how you hope a Wharton education would help you to explore it.\u202f\u202f(150-200 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_upenn_college_specific_-_the_school_of_engineering_and_applied_science",
                title: "College Specific - The School of Engineering and Applied Science",
                text: "Penn Engineering prepares its students to become leaders in technology by combining a strong foundation in the natural\u202fsciences and mathematics with depth of study in focused disciplinary majors. Please share how you plan to pursue your engineering interests at Penn. (150-200 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_upenn_program_specific_-_dmd",
                title: "Program Specific - DMD",
                text: "Discuss how your interests align with the Digital Media Design (DMD) program at the University of Pennsylvania? (400-650 words)",
                maxWordCount: 650,
                tag: "Optional",
            },
            {
                id: "2024_upenn_program_specific_-_huntsman",
                title: "Program Specific - Huntsman",
                text: "The Huntsman Program supports the development of globally minded scholars who become engaged citizens, creative innovators, and ethical leaders in the public, private, and non-profit sectors in the United States and internationally. What draws you to a dual-degree program in business and international studies, and how would you use what you learn to contribute to a global issue where business and international affairs intersect? (400-650 words)",
                maxWordCount: 650,
                tag: "Optional",
            },
            {
                id: "2024_upenn_program_specific_-_lsm",
                title: "Program Specific - LSM",
                text: "The LSM program aims to provide students with a fundamental understanding of the life sciences and their management with an eye to identifying, advancing, and implementing innovations. What issues would you want to address using the understanding gained from such a program? Note that this essay should be distinct from your single degree essay. (400-650 words)",
                maxWordCount: 650,
                tag: "Optional",
            },
            {
                id: "2024_upenn_program_specific_-_m&t_essay_1",
                title: "Program Specific - M&T Essay 1",
                text: "Explain how you will use the M&T program to explore your interest in business, engineering, and the intersection of the two. (400-650 words)",
                maxWordCount: 650,
                tag: "Optional",
            },
            {
                id: "2024_upenn_program_specific_-_m&t_essay_2",
                title: "Program Specific - M&T Essay 2",
                text: "Describe a problem that you solved that showed leadership and creativity. (250 words)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_upenn_program_specific_-_nhcm",
                title: "Program Specific - NHCM",
                text: "Discuss your interest in nursing and health care management. How might Penn's coordinated dual-degree program in nursing and business help you meet your goals? (400-650 words)",
                maxWordCount: 650,
                tag: "Optional",
            },
            {
                id: "2024_upenn_program_specific_-_viper",
                title: "Program Specific - VIPER",
                text: "How do you envision your participation in the Vagelos Integrated Program in Energy Research (VIPER) furthering your interests in energy science and technology? Please include any past experiences (ex. academic, research, or extracurricular) that have led to your interest in the program. Additionally, please indicate why you are interested in pursuing dual degrees in science and engineering and which VIPER majors are most interesting to you at this time. (400-650 words)",
                maxWordCount: 650,
                tag: "Optional",
            },
        ],
    },
    caltech: {
        prompts: [
            {
                id: "2024_caltech_question_1",
                title: "STEM Academic Interest Question",
                text: "Caltech has a rigorous core curriculum and students don't declare a major until the end of their first year. However, some students arrive knowing which academic fields and areas already most excite them, or which novel fields and areas they most want to explore.\n\nIf you had to choose an area of interest or two today, what would you choose?\n\n* Note that you are under no obligation to follow this choice if admitted.\n\nWhy did you choose your proposed area of interest? If you selected 'other', what topics are you interested in pursuing? (Min: 100 / Max: 200 words)",
                maxWordCount: 200,
            },
            {
                id: "2024_caltech_question_2",
                title: "STEM Curiosity Short Essay",
                text: "Regardless of your STEM interest listed above, take this opportunity to nerd out and talk to us about whatever STEM rabbit hole you have found yourself falling into. Be as specific or broad as you would like. (Min: 50 / Max: 150 words)",
                maxWordCount: 150,
            },
            {
                id: "2024_caltech_question_3",
                title: "STEM Experience Short Essay 1",
                text: "At Caltech, we investigate some of the most challenging, fundamental problems in science, technology, engineering, and mathematics. We are interested in learning more about your engagement with STEM.\n\nTell us how you initially found your interest and passion for science or any STEM topic, and how you have pursued or developed this interest or passion over time. (Min: 100 / Max: 200 words)",
                maxWordCount: 200,
            },
            {
                id: "2024_caltech_question_4",
                title: "STEM Experience Short Essay 2",
                text: "At Caltech, we investigate some of the most challenging, fundamental problems in science, technology, engineering, and mathematics. We are interested in learning more about your engagement with STEM.\n\nTell us about a STEM-related experience from the last few years and share how and why it inspired your curiosity. (Min: 100 / Max: 200 words)",
                maxWordCount: 200,
            },
            {
                id: "2024_caltech_question_5",
                title: "Creativity in Action Question",
                text: "The creativity, inventiveness, and innovation of Caltech's students, faculty, and researchers have won Nobel Prizes and put rovers on Mars. But Techers also imagine smaller-scale innovations every day, from new ways to design solar cells to how to 3D-print dorm decor to experimenting in the kitchen. How have you been a creator, inventor, or innovator in your own life? (Min: 200 / Max: 250 words)",
                maxWordCount: 250,
            },
            {
                id: "2024_caltech_question_6",
                title: "Caltech Values Question",
                text: "Caltech's values include respect for a diversity of thoughts and ideas. How have you cultivated this value in your own life? (Min: 50 / Max: 200 words)",
                maxWordCount: 200,
            },
            {
                id: "2024_caltech_question_7",
                title: "Short Answer 1",
                text: "If there are aspects of your life or social or personal identity that you feel are not captured elsewhere in this application, please tell us about them below. (Max: 150 words)",
                maxWordCount: 150,
                tag: "Optional",
            },
            {
                id: "2024_caltech_question_8",
                title: "Short Answer 2",
                text: "When not surveying the stars, peering through microscopes, or running through coding marathons, Caltech students pursue an eclectic array of interests that range from speed-cubing, reading, yoga, playing musical instruments and theater arts, to solving puzzles, hiking, painting, and building or inventing new gadgets. We understand that everyone needs an outlet or two. What is a favorite interest or hobby, and why does it bring you joy? (Max: 200 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_caltech_question_9",
                title: "Short Answer 3",
                text: "Did you have a hard time narrowing it down to just one interest or hobby? We understand – Caltech students like to stay busy, too. Tell us about another hobby or interest! (Max: 150 words)",
                maxWordCount: 150,
                tag: "Optional",
            },
        ],
    },
    duke: {
        prompts: [
            {
                id: "2024_duke_supplement_1",
                title: "Short Essay 1",
                text: "What is your sense of Duke as a university and a community, and why do you consider it a good match for you?  If there's something in particular about our offerings that attracts you, feel free to share that as well. (250-word limit)",
                maxWordCount: 250,
            },
            {
                id: "2024_duke_supplement_2_option_1",
                title: "Short Essay 2 Option 1",
                text: "We believe a wide range of viewpoints, beliefs, and lived experiences are essential to maintaining Duke as a vibrant and meaningful living and learning community. Feel free to share with us anything in this context that might help us better understand you and what you might bring to our community. (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_duke_supplement_2_option_2",
                title: "Short Essay 2 Option 2",
                text: "Tell us about an experience in the past year or two that reflects your imagination, creativity, or intellect. (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_duke_supplement_2_option_3",
                title: "Short Essay 2 Option 3",
                text: "We believe there is benefit in sharing or questioning our beliefs or values; who do you agree with on the big important things, or who do you have your most interesting disagreements with? What are you agreeing or disagreeing about? (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_duke_supplement_2_option_4",
                title: "Short Essay 2 Option 4",
                text: "Duke's commitment to inclusion and belonging includes sexual orientation, gender identity, and gender expression. Feel free to share with us more about how your identity in this context has meaning for you as an individual or as a member of a community. (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_duke_supplement_2_option_5",
                title: "Short Essay 2 Option 5",
                text: "We recognize that not fully “fitting in” a community or place can sometimes be difficult. Duke values the effort, resilience, and independence that may require. Feel free to share with us circumstances where something about you is different and how that's influenced your experiences or identity. (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
        ],
    },
    jhu: {
        prompts: [
            {
                id: "2024_jhu_supplement_1",
                title: "Essay 1",
                text: "How has your life experience contributed to your personal story—your character, values, perspectives, or skills—and what you want to pursue at Hopkins? (350-word limit)",
                maxWordCount: 350,
            },
        ],
    },
    northwestern: {
        prompts: [
            {
                id: "2024_northwestern_supplement_1",
                title: "Essay 1",
                text: "We want to be sure we're considering your application in the context of your personal experiences: What aspects of your background (your identity, your school setting, your community, your household, etc.) have most shaped how you see yourself engaging in Northwestern's community, be it academically, extracurricularly, culturally, politically, socially, or otherwise? (fewer than 300 words)",
                maxWordCount: 300,
            },
            {
                id: "2024_northwestern_supplement_2_option_1",
                title: "Essay 2 Option 1",
                text: "Painting “The Rock” is a tradition at Northwestern that invites all forms of expression—students promote campus events or extracurricular groups, support social or activist causes, show their Wildcat spirit (what we call “Purple Pride”), celebrate their culture, and more. What would you paint on The Rock, and why? (fewer than 200 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_northwestern_supplement_2_option_2",
                title: "Essay 2 Option 2",
                text: "Northwestern fosters a distinctively interdisciplinary culture. We believe discovery and innovation thrive at the intersection of diverse ideas, perspectives, and academic interests. Within this setting, if you could dream up an undergraduate class, research project, or creative effort (a start-up, a design prototype, a performance, etc.), what would it be? Who might be some ideal classmates or collaborators? (fewer than 200 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_northwestern_supplement_2_option_3",
                title: "Essay 2 Option 3",
                text: "Community and belonging matter at Northwestern. Tell us about one or more communities, networks, or student groups you see yourself connecting with on campus. (fewer than 200 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_northwestern_supplement_2_option_4",
                title: "Essay 2 Option 4",
                text: "Northwestern's location is special: on the shore of Lake Michigan, steps from downtown Evanston, just a few miles from Chicago. What aspects of our location are most compelling to you, and why? (fewer than 200 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_northwestern_supplement_2_option_5",
                title: "Essay 2 Option 5",
                text: "Northwestern is a place where people with diverse backgrounds from all over the world can study, live, and talk with one another. This range of experiences and viewpoints immeasurably enriches learning. How might your individual background contribute to this diversity of perspectives in Northwestern's classrooms and around our campus? (fewer than 200 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
        ],
    },
    columbia: {
        prompts: [
            {
                id: "2024_columbia_supplement_1",
                title: "List Question",
                text: "List a selection of texts, resources and outlets that have contributed to your intellectual development outside of academic courses, including but not limited to books, journals, websites, podcasts, essays, plays, presentations, videos, museums and other content that you enjoy. (100 words or fewer)",
                maxWordCount: 100,
            },
            {
                id: "2024_columbia_supplement_2",
                title: "Short Answer 1",
                text: "A hallmark of the Columbia experience is being able to learn and thrive in an equitable and inclusive community with a wide range of perspectives. Tell us about an aspect of your own perspective, viewpoint or lived experience that is important to you, and describe how it has shaped the way you would learn from and contribute to Columbia's diverse and collaborative community. (150 words or fewer)",
                maxWordCount: 150,
            },
            {
                id: "2024_columbia_supplement_3",
                title: "Short Answer 2",
                text: "In college/university, students are often challenged in ways that they could not predict or anticipate. It is important to us, therefore, to understand an applicant's ability to navigate through adversity. Please describe a barrier or obstacle you have faced and discuss the personal qualities, skills or insights you have developed as a result. (150 words or fewer)",
                maxWordCount: 150,
            },
            {
                id: "2024_columbia_supplement_4",
                title: "Short Answer 3",
                text: "Why are you interested in attending Columbia University? We encourage you to consider the aspect(s) that you find unique and compelling about Columbia. (150 words or fewer)",
                maxWordCount: 150,
            },
            {
                id: "2024_columbia_supplement_5",
                title: "Short Answer 4",
                text: "What attracts you to your preferred areas of study at Columbia College or Columbia Engineering? (150 words or fewer)",
                maxWordCount: 150,
            },
        ],
    },
    cornell: {
        prompts: [
            {
                id: "2024_cornell_required_question",
                title: "Required Question",
                text: "We all contribute to, and are influenced by, the communities that are meaningful to us. Share how you've been shaped by one of the communities you belong to. \n\nRemember that this essay is about you and your lived experience. Define community in the way that is most meaningful to you. Some examples of community you might choose from are: family, school, shared interest, virtual, local, global, cultural. (350 word limit)",
                maxWordCount: 350,
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_cornell_college_specific_-_agriculture_and_life_sciences_question_1",
                title: "College Specific - Agriculture and Life Sciences Question 1",
                text: "By applying to Cornell's College of Agriculture of Life Sciences (CALS), you are also applying for direct entry into one of our 20+ majors. From here, you would be part of a community dedicated to purpose-driven science; working within your major and across disciplines to tackle the complex challenges of our time.\u202f\n\nWhy are you drawn to studying the major you have selected and specifically, why do you want to pursue this major at Cornell CALS? You should share how your current interests, related experiences, and/or goals influenced your choice. (500-word limit)",
                maxWordCount: 500,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_cornell_college_specific_-_agriculture_and_life_sciences_short_answer_1",
                title: "College Specific - Agriculture and Life Sciences Short Answer 1",
                text: "At Cornell CALS, we aim to leave the world better than we found it, so we seek out those who are passionate about serving the public good. Please elaborate on an experience where you were able to have a meaningful impact on people, a community, and/or an environment of importance to you. (100-word limit)",
                maxWordCount: 100,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_cornell_college_specific_-_agriculture_and_life_sciences_short_answer_2",
                title: "College Specific - Agriculture and Life Sciences Short Answer 2",
                text: 'Cornell CALS is dedicated to purpose-driven study of the agricultural, life, environmental, and social sciences and welcomes students with interests that span a wide variety of disciplines. Given our agricultural history and commitment to educating the next generation of agriculturalists, please share if you have a background or interest in agriculture, regardless of your intended major. An "agricultural entity" for the purpose of this question is defined as cultivating soil, growing crops, and raising livestock (e.g., farm, ranch, greenhouse, vineyard, etc.). \n\nSelect all that apply:\n\n* A primary source of income for my parent/guardian(s) comes from ownership of or employment by an agricultural entity.\n* My extended family owns or operates an agricultural entity.\n* I have experience working in an agricultural entity.\n* I have interest in pursuing a career in an agricultural entity.\n\nPlease feel free to share additional details (optional). (100-word limit)',
                maxWordCount: 100,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_cornell_college_specific_-_architecture",
                title: "College Specific - Architecture",
                text: "How do your interests directly connect with your intended major at the College of Architecture, Art, and Planning (AAP)? Why architecture (B.Arch), art (BFA), or urban and regional studies (URS)? B. Arch applicants, please provide an example of how a creative project or passion sparks your motivation to pursue a 5-year professional degree program. BFA applicants may want to to consider how they could integrate a range of interests and available resources at Cornell into a coherent art practice. URS students may want to emphasize their enthusiasm and depth of interest in the study of urban and regional issues. (650 word limit)",
                maxWordCount: 650,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_cornell_college_specific_-_arts_and_sciences",
                title: "College Specific - Arts and Sciences",
                text: "At the College of Arts and Sciences, curiosity will be your guide. Discuss how your passion for learning is shaping your academic journey, and what areas of study or majors excite you and why. Your response should convey how your interests align with the College, and how you would take advantage of the opportunities and curriculum in Arts and Sciences. (650 word limit)",
                maxWordCount: 650,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_cornell_college_specific_-_public_policy",
                title: "College Specific - Public Policy",
                text: "Why are you drawn to studying public policy? Drawing on your experiences, tell us about why you are interested in your chosen major and how attending the Brooks School will help you achieve your life goals. (650 word limit)",
                maxWordCount: 650,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_cornell_college_specific_-_business",
                title: "College Specific - Business",
                text: "What kind of a business student are you? Using your personal, academic, or volunteer/work experiences, describe the topics or issues that you care about and why they are important to you. Your response should convey how your interests align with the school to which you are applying within the Cornell SC Johnson College of Business (Charles H. Dyson School of Applied Economics and Management or the Peter and Stephanie Nolan School of Hotel Administration). (650 word limit)",
                maxWordCount: 650,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_cornell_college_specific_-_engineering_long_essay_1",
                title: "College Specific - Engineering Long Essay 1",
                text: "Question 1: Fundamentally, engineering is the application of math, science, and technology to solve complex problems. Why do you want to study engineering? (200 word limit)",
                maxWordCount: 200,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_cornell_college_specific_-_engineering_long_essay_2",
                title: "College Specific - Engineering Long Essay 2",
                text: "Question 2: Why do you think you would love to study at Cornell Engineering? (200 word limit)",
                maxWordCount: 200,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_cornell_college_specific_-_engineering_short_answer_1",
                title: "College Specific - Engineering Short Answer 1",
                text: "What brings you joy? (100 word limit)",
                maxWordCount: 100,
                tag: "Optional",
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_cornell_college_specific_-_engineering_short_answer_2",
                title: "College Specific - Engineering Short Answer 2",
                text: "What do you believe you will contribute to the Cornell Engineering community beyond what you've already detailed in your application? What unique voice will you bring? (100 word limit)",
                maxWordCount: 100,
                tag: "Optional",
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_cornell_college_specific_-_engineering_short_answer_3",
                title: "College Specific - Engineering Short Answer 3",
                text: "What is one activity, club, team, organization, work/volunteer experience or family responsibility that is especially meaningful to you? Please briefly tell us about its significance for you. (100 word limit)",
                maxWordCount: 100,
                tag: "Optional",
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_cornell_college_specific_-_engineering_short_answer_4",
                title: "College Specific - Engineering Short Answer 4",
                text: "What is one award you have received or achievement you have attained that has meant the most to you? Please briefly describe its importance to you. (100 word limit)",
                maxWordCount: 100,
                tag: "Optional",
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_cornell_college_specific_-_human_ecology",
                title: "College Specific - Human Ecology",
                text: "How has your decision to apply to the College of Human Ecology (CHE) been shaped and informed by your related experiences? How will what you learn through CHE and your chosen major impact your goals and plans for the future? Your response should demonstrate how your interests and aspirations align with CHE's programs and mission. (Refer to our essay application tips before you begin.) (650 word limit)",
                maxWordCount: 650,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_cornell_college_specific_-_industrial_and_labor_relations",
                title: "College Specific - Industrial and Labor Relations",
                text: "Using your personal, academic, or volunteer/work experiences, describe the topics or issues that you care about and why they are important to you. Your response should show us that your interests align with the ILR School. (650 word limit)",
                maxWordCount: 650,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
        ],
    },
    uchicago: {
        prompts: [
            {
                id: "2024_uchicago_supplement_1",
                title: "Essay 1",
                text: "How does the University of Chicago, as you know it now, satisfy your desire for a particular kind of learning, community, and future? Please address with some specificity your own wishes and how they relate to UChicago. (1-2 pages)",
                maxWordCount: 1500,
            },
            {
                id: "2024_uchicago_supplement_2_option_1",
                title: "Essay 2 Option 1",
                text: "We're all familiar with green-eyed envy or feeling blue, but what about being “caught purple-handed”? Or “tickled orange”? Give an old color-infused expression a new hue and tell us what it represents.\nInspired by Ramsey Bottorff, Class of 2026 (1-2 pages)",
                maxWordCount: 1500,
                tag: "Optional",
            },
            {
                id: "2024_uchicago_supplement_2_option_2",
                title: "Essay 2 Option 2",
                text: "“Ah, but I was so much older then / I'm younger than that now” – Bob Dylan. In what ways do we become younger as we get older?\nInspired by Joshua Harris, Class of 2016 (1-2 pages)",
                maxWordCount: 1500,
                tag: "Optional",
            },
            {
                id: "2024_uchicago_supplement_2_option_3",
                title: "Essay 2 Option 3",
                text: "Pluto, the demoted planet. Ophiuchus, the thirteenth Zodiac. Andy Murray, the fourth to tennis's Big Three. Every grouping has something that doesn't quite fit in. Tell us about a group and its unofficial member, and why (or why not) it should be excluded.\nInspired by Veronica Chang, Class of 2022 (1-2 pages)",
                maxWordCount: 1500,
                tag: "Optional",
            },
            {
                id: "2024_uchicago_supplement_2_option_4",
                title: "Essay 2 Option 4",
                text: "“Daddy-o”, “Far Out”, “Gnarly”: the list of slang terms goes on and on. Sadly, most of these aren't so “fly” anymore – “as if!” Name an outdated slang from any decade or language that you'd bring back and explain why you totally “dig it.”\nInspired by Napat Sakdibhornssup, Class of 2028 (1-2 pages)",
                maxWordCount: 1500,
                tag: "Optional",
            },
            {
                id: "2024_uchicago_supplement_2_option_5",
                title: "Essay 2 Option 5",
                text: "How many piano tuners are there in Chicago? What is the total length of chalk used by UChicago professors in a year? How many pages of books are in the Regenstein Library? These questions are among a class of estimation problems named after University of Chicago physicist Enrico Fermi. Create your own Fermi estimation problem, give it your best answer, and show us how you got there.\nInspired by Malhar Manek, Class of 2028 (1-2 pages)",
                maxWordCount: 1500,
                tag: "Optional",
            },
            {
                id: "2024_uchicago_supplement_2_option_6",
                title: "Essay 2 Option 6",
                text: "And, as always… the classic choose your own adventure option! In the spirit of adventurous inquiry, choose one of our past prompts (or create a question of your own). Be original, creative, thought-provoking. Draw on your best qualities as a writer, thinker, visionary, social critic, sage, citizen of the world, or future citizen of the University of Chicago; take a little risk, and have fun! (1-2 pages)",
                maxWordCount: 1500,
                tag: "Optional",
            },
        ],
    },
    uc: {
        prompts: [
            {
                id: "2024_uc_personal_insight_question_1",
                title: "Personal Insight Question 1",
                text: "Describe an example of your leadership experience in which you have positively influenced others, helped resolve disputes or contributed to group efforts over time. (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
            {
                id: "2024_uc_personal_insight_question_2",
                title: "Personal Insight Question 2",
                text: "Every person has a creative side, and it can be expressed in many ways: problem solving, original and innovative thinking, and artistically, to name a few. Describe how you express your creative side. (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
            {
                id: "2024_uc_personal_insight_question_3",
                title: "Personal Insight Question 3",
                text: "What would you say is your greatest talent or skill? How have you developed and demonstrated that talent over time? (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
            {
                id: "2024_uc_personal_insight_question_4",
                title: "Personal Insight Question 4",
                text: "Describe how you have taken advantage of a significant educational opportunity or worked to overcome an educational barrier you have faced. (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
            {
                id: "2024_uc_personal_insight_question_5",
                title: "Personal Insight Question 5",
                text: "Describe the most significant challenge you have faced and the steps you have taken to overcome this challenge. How has this challenge affected your academic achievement? (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
            {
                id: "2024_uc_personal_insight_question_6",
                title: "Personal Insight Question 6",
                text: "Think about an academic subject that inspires you. Describe how you have furthered this interest inside and/or outside of the classroom. (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
            {
                id: "2024_uc_personal_insight_question_7",
                title: "Personal Insight Question 7",
                text: "What have you done to make your school or your community a better place? (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
            {
                id: "2024_uc_personal_insight_question_8",
                title: "Personal Insight Question 8",
                text: "Beyond what has already been shared in your application, what do you believe makes you a strong candidate for admissions to the University of California? (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
        ],
    },
    rice: {
        prompts: [
            {
                id: "2024_rice_supplement_1",
                title: "Essay 1",
                text: "Please explain why you wish to study in the academic areas you selected. (150-word limit)",
                maxWordCount: 150,
            },
            {
                id: "2024_rice_supplement_2",
                title: "Essay 2",
                text: "Based upon your exploration of Rice University, what elements of the Rice experience appeal to you? (150-word limit)",
                maxWordCount: 150,
            },
            {
                id: "2024_rice_supplement_3_option_1",
                title: "Essay 3 Option 1",
                text: "The Residential College System is at the heart of Rice student life and is heavily influenced by the particular cultural traditions and unique life experiences each student brings. What life experiences and/or unique perspectives are you looking forward to sharing with fellow Owls in the residential college system? (500-word limit)",
                maxWordCount: 500,
                tag: "Optional",
            },
            {
                id: "2024_rice_supplement_3_option_2",
                title: "Essay 3 Option 2",
                text: "Rice is strengthened by its diverse community of learning and discovery that produces leaders and change agents across the spectrum of human endeavor. What perspectives shaped by your background, experiences, upbringing, and/or racial identity inspire you to join our community of change agents at Rice? (500-word limit)",
                maxWordCount: 500,
                tag: "Optional",
            },
        ],
    },
    dartmouth: {
        prompts: [
            {
                id: "2024_dartmouth_question_1",
                title: "Question 1",
                text: "As you seek admission to Dartmouth's Class of 2029, what aspects of the college's academic program, community, and/or campus environment attract your interest? How is Dartmouth a good fit for you? (100 words or fewer)",
                maxWordCount: 100,
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_dartmouth_question_2_option_1",
                title: "Question 2 Option 1",
                text: "There is a Quaker saying: Let your life speak. Describe the environment in which you were raised and the impact it has had on the person you are today. (250 words or fewer)",
                maxWordCount: 250,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_dartmouth_question_2_option_2",
                title: "Question 2 Option 2",
                text: "“Be yourself,” Oscar Wilde advised. “Everyone else is taken.” Introduce yourself. (250 words or fewer)",
                maxWordCount: 250,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_dartmouth_question_3_option_1",
                title: "Question 3 Option 1",
                text: "What excites you? (250 words or fewer)",
                maxWordCount: 250,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_dartmouth_question_3_option_2",
                title: "Question 3 Option 2",
                text: 'Labor leader and civil rights activist Dolores Huerta recommended a life of purpose. "We must use our lives to make the world a better place to live, not just to acquire things," she said. "That is what we are put on the earth for." In what ways do you hope to make—or are you already making—an impact? Why? How? (250 words or fewer)',
                maxWordCount: 250,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_dartmouth_question_3_option_3",
                title: "Question 3 Option 3",
                text: 'In "Oh, The Places You\'ll Go," Dr. Seuss invites us to "Think and wonder. Wonder and think." Imagine your anticipated academic major: How does that course of study sync with Dr. Seuss\'s advice to you? (250 words or fewer)',
                maxWordCount: 250,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_dartmouth_question_3_option_4",
                title: "Question 3 Option 4",
                text: "The social and family interactions of wild chimpanzees have been the focus of Dame Jane Goodall's research for decades. Her understanding of animal behavior prompted the English primatologist to see a lesson for human communities as well: \"Change happens by listening and then starting a dialogue with the people who are doing something you don't believe is right.\" Channel Dame Goodall: Tell us about a moment when you engaged in a difficult conversation or encountered someone with an opinion or perspective that was different from your own. How did you find common ground? (250 words or fewer)",
                maxWordCount: 250,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_dartmouth_question_3_option_5",
                title: "Question 3 Option 5",
                text: "Celebrate your nerdy side. (250 words or fewer)",
                maxWordCount: 250,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_dartmouth_question_3_option_6",
                title: "Question 3 Option 6",
                text: "It's not easy being green… was the frequent refrain of Kermit the Frog. How has difference been a part of your life, and how have you embraced it as part of your identity, outlook, or sense of purpose? (250 words or fewer)",
                maxWordCount: 250,
                tag: "Optional",
                supplementalEssayCategory: "Uncategorized",
            },
            {
                id: "2024_dartmouth_question_3_option_7",
                title: "Question 3 Option 7",
                text: 'Buddy Teevens \'79 was a legendary and much-beloved coach at Dartmouth. He often told parents: "Your son will be a great football player when it\'s football time, a great student when it\'s academic time, and a great person all of the time." If Coach Teevens had said that to you, what would it mean to be "a great person"? (250 words or fewer)',
                maxWordCount: 250,
                tag: "Optional",
                supplementalEssayCategory: "Uncategorized",
            },
        ],
    },
    vanderbilt: {
        prompts: [
            {
                id: "2024_vanderbilt_short_answer_option_1",
                title: "Short Answer Option 1",
                text: "Vanderbilt University values learning through contrasting points of view. We understand that our differences, and our respect for alternative views and voices, are our greatest source of strength. Please reflect on conversations you've had with people who have expressed viewpoints different from your own. How did these conversations/experiences influence you? (approximately 250 words)",
                maxWordCount: 250,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_vanderbilt_short_answer_option_2",
                title: "Short Answer Option 2",
                text: "Vanderbilt offers a community where students find balance between their academic and social experiences. Please briefly elaborate on how one of your extracurricular activities or work experiences has influenced you. (approximately 250 words)",
                maxWordCount: 250,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
        ],
    },
    notredame: {
        prompts: [
            {
                id: "2024_notredame_supplement_1",
                title: "Short Essay",
                text: "Everyone has different priorities when considering their higher education options and building their college or university list. Tell us about your “non-negotiable” factor(s) when searching for your future college home.",
                maxWordCount: 150,
            },
            {
                id: "2024_notredame_supplement_2_option_1",
                title: "Short Answer Option 1",
                text: "How does faith influence the decisions you make?",
                maxWordCount: 100,
                tag: "Optional",
            },
            {
                id: "2024_notredame_supplement_2_option_2",
                title: "Short Answer Option 2",
                text: "What is distinctive about your personal experiences and development (eg, family support, culture, disability, personal background, community, etc)? Why are these experiences important to you and how will you enrich the Notre Dame community?",
                maxWordCount: 100,
                tag: "Optional",
            },
            {
                id: "2024_notredame_supplement_2_option_3",
                title: "Short Answer Option 3",
                text: "Notre Dame's undergraduate experience is characterized by a collective sense of care for every person. How do you foster service to others in your community?",
                maxWordCount: 100,
                tag: "Optional",
            },
            {
                id: "2024_notredame_supplement_2_option_4",
                title: "Short Answer Option 4",
                text: "What compliment are you most proud of receiving, and why does it mean so much to you?",
                maxWordCount: 100,
                tag: "Optional",
            },
            {
                id: "2024_notredame_supplement_2_option_5",
                title: "Short Answer Option 5",
                text: "What would you fight for?",
                maxWordCount: 100,
                tag: "Optional",
            },
        ],
    },
    umich: {
        prompts: [
            {
                id: "2024_umich_question_1",
                title: "Essay 1",
                text: "Everyone belongs to many different communities and/or groups defined by (among other things) shared geography, religion, ethnicity, income, cuisine, interest, race, ideology, or intellectual heritage. Choose one of the communities to which you belong, and describe that community and your place within it. (Required for all applicants; Min: 100 / Max: 300 words)",
                maxWordCount: 300,
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_umich_question_2",
                title: "Essay 2",
                text: "Describe the unique qualities that attract you to the specific undergraduate College or School (including preferred admission and dual degree programs) to which you are applying at the University of Michigan. How would that curriculum support your interests? (Required for all applicants; Min: 100 / Max: 550 words)",
                maxWordCount: 550,
                supplementalEssayCategory: "Why This School/Major",
            },
        ],
    },
    georgetown: {
        prompts: [
            {
                id: "2024_georgetown_supplement_1",
                title: "Essay 1",
                text: "Please elaborate on any special talents or skills you would like to highlight (250 words).",
                maxWordCount: 250,
            },
            {
                id: "2024_georgetown_supplement_2",
                title: "Essay 2",
                text: "Briefly discuss the significance to you of the school or summer activity in which you have been most involved (approximately 1/2 page, single-spaced).",
                maxWordCount: 400,
            },
            {
                id: "2024_georgetown_supplement_3",
                title: "Essay 3",
                text: "Georgetown is a diverse community, the Admissions Committee would like to know more about you in your own words. Please submit a brief personal or creative essay which you feel best describes you and reflects on your own background, identity, skills, and talents. (approximately 1 page, single-spaced)",
                maxWordCount: 750,
            },
            {
                id: "2024_georgetown_college_specific_-_georgetown_college_of_arts_and_sciences",
                title: "College Specific - Georgetown College of Arts and Sciences",
                text: "A liberal arts education from the College of Arts & Sciences involves encounters with new concepts and modes of inquiry. Describe something (a class, a book, an event, etc.) that changed your thinking. (Applicants to the sciences, mathematics, public policy or languages are encouraged to include examples related to that field.) (approximately 1 page, single-spaced)",
                maxWordCount: 750,
                tag: "Optional",
            },
            {
                id: "2024_georgetown_college_specific_-_mcdonough_school_of_business",
                title: "College Specific - McDonough School of Business",
                text: "The McDonough School of Business is a national and global leader in providing graduates with essential ethical, analytical, financial and global perspectives. Please discuss your motivations for studying business at Georgetown. (approximately 1 page, single-spaced)",
                maxWordCount: 750,
                tag: "Optional",
            },
            {
                id: "2024_georgetown_college_specific_-_walsh_school_of_foreign_service",
                title: "College Specific - Walsh School of Foreign Service",
                text: "The Walsh School of Foreign Service was founded more than a century ago to prepare generations of leaders to solve global problems. What is motivating you to dedicate your undergraduate studies to a future in service to the world? (approximately 1 page, single-spaced)",
                maxWordCount: 750,
                tag: "Optional",
            },
            {
                id: "2024_georgetown_college_specific_-_school_of_health",
                title: "College Specific - School of Health",
                text: "Describe the factors that have influenced your interest in studying health care at Georgetown University. Please specifically address your intended major (Global Health, Health Care Management & Policy, or Human Science). (approximately 1 page, single-spaced)",
                maxWordCount: 750,
                tag: "Optional",
            },
            {
                id: "2024_georgetown_college_specific_-_school_of_nursing",
                title: "College Specific - School of Nursing",
                text: "Georgetown University's School of Nursing is committed to the formation of ethical, empathetic, and transformational nursing leaders. Describe the factors that have influenced your interest in studying Nursing at Georgetown University. (approximately 1 page, single-spaced)",
                maxWordCount: 750,
                tag: "Optional",
            },
        ],
    },
    unc: {
        prompts: [
            {
                id: "2024_unc_supplement_1",
                title: "Short Answer 1",
                text: "Discuss one of your personal qualities and share a story, anecdote, or memory of how it helped you make a positive impact on a community. This could be your current community or another community you have engaged. (250-word limit)",
                maxWordCount: 250,
            },
            {
                id: "2024_unc_short_answer_2",
                title: "Short Answer 2",
                text: "Discuss an academic topic that you're excited to explore and learn more about in college. Why does this topic interest you? Topics could be a specific course of study, research interests, or any other area related to your academic experience in college. (250-word limit)",
                maxWordCount: 250,
            },
        ],
    },
    cmu: {
        prompts: [
            {
                id: "2024_cmu_supplement_1",
                title: "Essay 1",
                text: "Most students choose their intended major or area of study based on a passion or inspiration that's developed over time – what passion or inspiration led you to choose this area of study? (300-word limit)",
                maxWordCount: 300,
            },
            {
                id: "2024_cmu_supplement_2",
                title: "Essay 2",
                text: "Many students pursue college for a specific degree, career opportunity or personal goal. Whichever it may be, learning will be critical to achieve your ultimate goal. As you think ahead to the process of learning during your college years, how will you define a successful college experience? (300-word limit)",
                maxWordCount: 300,
            },
            {
                id: "2024_cmu_supplement_3",
                title: "Essay 3",
                text: "Consider your application as a whole. What do you personally want to emphasize about your application for the admission committee's consideration? Highlight something that's important to you or something you haven't had a chance to share. Tell us, don't show us (no websites please). (300-word limit)",
                maxWordCount: 300,
            },
        ],
    },
    emory: {
        prompts: [
            {
                id: "2024_emory_supplement_1",
                title: "Essay 1",
                text: "What academic areas are you interested in exploring at Emory University and why? (150 words recommended length; 200-word limit)",
                maxWordCount: 200,
            },
            {
                id: "2024_emory_supplement_2_option_1",
                title: "Essay 2 Option 1",
                text: "Which book, character, song, monologue, or piece of work (fiction or non-fiction) seems made for you? Why? (150-word limit)",
                maxWordCount: 150,
                tag: "Optional",
            },
            {
                id: "2024_emory_supplement_2_option_2",
                title: "Essay 2 Option 2",
                text: "Reflect on a personal experience where you intentionally expanded your cultural awareness. (150-word limit)",
                maxWordCount: 150,
                tag: "Optional",
            },
            {
                id: "2024_emory_supplement_2_option_3",
                title: "Essay 2 Option 3",
                text: "Emory University's unique mission calls for service to humanity. Share how you might personally contribute to this mission of service to humanity. (150-word limit)",
                maxWordCount: 150,
                tag: "Optional",
            },
            {
                id: "2024_emory_supplement_2_option_4",
                title: "Essay 2 Option 4",
                text: "Emory University has a strong commitment to building community. Tell us about a community that you have been part of where your participation helped to change or shape the community for the better. (150-word limit)",
                maxWordCount: 150,
                tag: "Optional",
            },
        ],
    },
    uva: {
        prompts: [
            {
                id: "2024_uva_supplement_1",
                title: "Essay 1",
                text: "What about your individual background, perspective, or experience will serve as a source of strength for you or those around you at UVA? Feel free to write about any past experience or part of your background that has shaped your perspective and will be a source of strength, including but not limited to those related to your community, upbringing, educational environment, race, gender, or other aspects of your background that are important to you. (Around 250 words)",
                maxWordCount: 300,
            },
            {
                id: "2024_uva_supplement_2",
                title: "Essay 2",
                text: "If you have a personal or historic connection with UVA, and if you'd like to share how your experience of this connection has prepared you to contribute to the university, please share your thoughts here. Such relationships might include, but are not limited to, being a child of someone who graduated from or works for UVA, a descendant of ancestors who labored at UVA, or a participant in UVA programs. (100-word limit)",
                maxWordCount: 100,
                tag: "Optional",
                supplementalEssayCategory: "Tiny Prompt",
            },
        ],
    },
    washu: {
        prompts: [
            {
                id: "2024_washu_supplement_1",
                title: "Essay 1",
                text: "Please tell us what you are interested in studying at college and why.\n\nUndecided about your academic interest(s)? Don't worry—tell us what excites you about the academic division you selected. Remember that all of our first-year students enter officially “undeclared” and work closely with their team of academic advisors to discover their academic passions. You can explore all of our majors and programs on our website. (200-word limit)",
                maxWordCount: 200,
            },
            {
                id: "2024_washu_supplement_2_option_1",
                title: "Essay 2 Option 1",
                text: "WashU supports engagement in the St. Louis community by considering the university as “In St. Louis, For St. Louis.” What is a community you are a part of and your place or impact within in? (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_washu_supplement_2_option_2",
                title: "Essay 2 Option 2",
                text: "WashU strives to know every undergraduate student “By Name & Story.” How have your life experiences shaped your story? (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
        ],
    },
    uflorida: {
        prompts: [
            {
                id: "2024_uflorida_essay_1",
                title: "Essay 1",
                text: "Please provide more details on your most meaningful commitment outside of the classroom while in high school and explain why it was meaningful. This could be related to an extracurricular activity, work, volunteering, an academic activity, family responsibility, or any other non-classroom activity. (250-word limit)",
                maxWordCount: 250,
            },
        ],
    },
    usc: {
        prompts: [
            {
                id: "2024_usc_supplement_1",
                title: "Essay 1",
                text: "Describe how you plan to pursue your academic interests at USC. Please feel free to address your first- and second-choice major selections. (250-word limit)",
                maxWordCount: 250,
            },
            {
                id: "2024_usc_supplement_2",
                title: "Essay 2",
                text: "Describe yourself in three words (25 characters).\n1.\n2.\n3.",
                maxWordCount: 35,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_usc_supplement_3",
                title: "Essay 3",
                text: "The following prompts have a 100 character limit:\n\n* What is your favorite snack:\n* Best movie of all time:\n* Dream job:\n* If your life had a theme song, what would it be:\n* Dream trip:\n* What TV show will you binge watch next:\n* Which well-known person or fictional character would be your ideal roommate:\n* Favorite book:\n* If you could teach a class on any topic, what would it be:",
                maxWordCount: 250,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_usc_college_specific_-_dornsife",
                title: "College Specific - Dornsife",
                text: "Many of us have at least one issue or passion that we care deeply about – a topic on which we would love to share our opinions and insights in hopes of sparking intense interest and continued conversation. If you had ten minutes and the attention of a million people, what would your talk be about? (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_usc_college_specific_-_viterbi_1",
                title: "College Specific - Viterbi Essay 1",
                text: "The student body at the USC Viterbi School of Engineering is a diverse group of unique engineers and computer scientists who work together to engineer a better world for all humanity. Describe how your contributions to the USC Viterbi student body may be distinct from others. Please feel free to touch on any part of your background, traits, skills, experiences, challenges, and/or personality in helping us better understand you. (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_usc_college_specific_-_viterbi_2",
                title: "College Specific - Viterbi Essay 2",
                text: "The National Academy of Engineering (NAE) and their 14 Grand Challenges go hand-in-hand with our vision to engineer a better world for all humanity. Engineers and computer scientists are challenged to solve these problems in order to improve life on the planet. Learn more about the NAE Grand Challenges at http://engineeringchallenges.org and tell us which challenge is most important to you, and why. (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
        ],
    },
    utaustin: {
        prompts: [
            {
                id: "2024_ut_austin_summer-fall_2025_question_1",
                title: "Summer/Fall 2025 Question 1",
                text: "Share an essay on any topic of your choice. It can be one you've already written, one that responds to a different prompt, or one of your own design. (500-650 words)",
                maxWordCount: 650,
                supplementalEssayCategory: "Uncategorized",
            },
            {
                id: "2024_ut_austin_summer-fall_2025_short_answer_1",
                title: "Summer/Fall 2025 Short Answer 1",
                text: "Why are you interested in the major you indicated as your first-choice major? (250-300 words)",
                maxWordCount: 300,
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_ut_austin_summer-fall_2025_short_answer_2",
                title: "Summer/Fall 2025 Short Answer 2",
                text: "Think of all the activities — both in and outside of school — that you have been involved with during high school. Which one are you most proud of and why? (Guidance for students: This can include an extracurricular activity, a club/organization, volunteer activity, work or a family responsibility.) (250-300 words)",
                maxWordCount: 300,
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_ut_austin_summer-fall_2025_optional_short_answer",
                title: "Summer/Fall 2025 Optional Short Answer",
                text: "Please share background on events or special circumstances that you feel may have impacted your high school academic performance. (250-300 words)",
                maxWordCount: 300,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_ut_austin_spring_2025_question_1",
                title: "Spring 2025 Question 1",
                text: "Tell us your story. What unique opportunities or challenges have you experienced throughout your high school career that have shaped who you are today? (500-650 words)",
                maxWordCount: 650,
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_ut_austin_spring_2025_short_answer_1",
                title: "Spring 2025 Short Answer 1",
                text: "Why are you interested in the major you indicated as your first-choice major? (250-300 words)",
                maxWordCount: 300,
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_ut_austin_spring_2025_short_answer_2",
                title: "Spring 2025 Short Answer 2",
                text: "Describe how your experiences, perspectives, talents, and/or your involvement in leadership activities (at your school, job, community or within your family) will help you to make an impact both in and out of the classroom while enrolled at UT. (250-300 words)",
                maxWordCount: 300,
                supplementalEssayCategory: "Campus Contribution",
            },
            {
                id: "2024_ut_austin_spring_2025_short_answer_3",
                title: "Spring 2025 Short Answer 3",
                text: "The core purpose of The University of Texas at Austin is, “To Transform Lives for the Benefit of Society.” Please share how you believe your experience at UT Austin will prepare you to “Change the World” after you graduate. (250-300 words)",
                maxWordCount: 300,
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_ut_austin_spring_2025_optional_short_answer",
                title: "Spring 2025 Optional Short Answer",
                text: "Please share background on events or special circumstances that you feel may have impacted your high school academic performance. (250-300 words)",
                maxWordCount: 300,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
        ],
    },
    georgiatech: {
        prompts: [
            {
                id: "2024_georgiatech_supplement_1",
                title: "Short Answer 1",
                text: "Why do you want to study your chosen major, and why do you want to study that major at Georgia Tech? (300-word limit)",
                maxWordCount: 300,
            },
        ],
    },
    nyu: {
        prompts: [
            {
                id: "2024_nyu_question_1",
                title: "Question 1",
                text: "In a world where disconnection seems to often prevail, we are looking for students who embody the qualities of bridge builders—students who can connect people, groups, and ideas to span divides, foster understanding, and promote collaboration within a dynamic, interconnected, and vibrant global academic community. We are eager to understand how your experiences have prepared you to build the bridges of the future. \n\nPlease consider one or more of the following questions in your essay:\n* What personal experiences or challenges have shaped you as a bridge builder?\n* How have you been a bridge builder in your school, community, or personal life?\n* What specific actions have you taken to build bridges between diverse groups, ideas, or cultures?\n* How do you envision being a bridge builder during your time at our university and beyond?",
                maxWordCount: 250,
                tag: "Optional",
            },
        ],
    },
    uiuc: {
        prompts: [
            {
                id: "2024_uiuc_major_specific_1",
                title: "Major Specific - Essay 1",
                text: "If You're Applying to a Major:\n\nExplain, in detail, an experience you've had in the past 3 to 4 years related to your first-choice major. This can be an experience from an extracurricular activity, in a class you've taken, or through something else. (Approximately 150 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_uiuc_major_specific_2",
                title: "Major Specific - Essay 2",
                text: "If You're Applying to a Major:\n\nDescribe your personal and/or career goals after graduating from UIUC and how your selected first-choice major will help you achieve them. (Approximately 150 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_uiuc_undeclared_1",
                title: "Undeclared - Essay 1",
                text: "If You're Applying to Our Undeclared Program in the Division of General Studies:\n\nWhat are your academic interests? Please include 2-3 majors you're considering at Illinois and why. (Approximately 150 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_uiuc_undeclared_2",
                title: "Undeclared - Essay 2",
                text: "If You're Applying to Our Undeclared Program in the Division of General Studies:\n\nWhat are your future career or academic goals? You may include courses you took in high school and how these impacted your goals.What are your future career or academic goals? You may include courses you took in high school and how these impacted your goals. (Approximately 150 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
            {
                id: "2024_uiuc_second_major",
                title: "Second Major - Essay 1",
                text: "If You've Selected a Second-Choice Major (Including Undeclared):\n\nPlease explain your interest in your second-choice major or your overall academic or career goals. (Approximately 150 words)",
                maxWordCount: 200,
                tag: "Optional",
            },
        ],
    },
    wisc: {
        prompts: [
            {
                id: "2024_wisc_question_1",
                title: "Question 1",
                text: "Tell us why you would like to apply to the University of Wisconsin-Madison. In addition, please include why you are interested in studying the major(s) you have selected. If you selected undecided please describe your areas of possible academic interest. (You may enter up to 650 words, but 300-500 is recommended).",
                maxWordCount: 650,
                supplementalEssayCategory: "Why This School/Major",
            },
        ],
    },
    bostoncollege: {
        prompts: [
            {
                id: "2024_bostoncollege_supplemental_option_1",
                title: "Essay Option 1",
                text: "Each year at University Convocation, our incoming class engages in reflective dialogue with the author of a common text. What book by a living author would you recommend for your incoming class to read, and why would this be an important shared text? (400-word limit)",
                maxWordCount: 400,
                tag: "Optional",
                supplementalEssayCategory: "Uncategorized",
            },
            {
                id: "2024_bostoncollege_supplemental_option_2",
                title: "Essay Option 2",
                text: "At Boston College, we draw upon the Jesuit tradition of finding worthwhile conversation partners. Some support our viewpoints while others challenge them. Who fulfills this role in your life? Please cite a specific conversation you had where this conversation partner challenged your perspective or you challenged theirs. (400-word limit)",
                maxWordCount: 400,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_bostoncollege_supplemental_option_3",
                title: "Essay Option 3",
                text: "In her November 2019 Ted Talk, “The Danger of a Single Story,” Chimamanda Ngozi Adichi warned viewers against assigning people a “single story” through assumptions about their nationality, appearance, or background.  Discuss a time when someone defined you by a single story. What challenges did this present and how did you overcome them? (400-word limit)",
                maxWordCount: 400,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_bostoncollege_supplemental_option_4",
                title: "Essay Option 4",
                text: "Boston College's founding in 1863 was in response to society's call. That call came from an immigrant community in Boston seeking a Jesuit education to foster social mobility. Still today, the University empowers its students to use their education to address society's greatest needs. Which of today's local or global issues is of particular concern to you and how might you use your Boston College education to address it? (400-word limit)",
                maxWordCount: 400,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_bostoncollege_human-centered_engineering_applicants_option_5",
                title: "Human-Centered Engineering Applicants Option 5",
                text: "One goal of a Jesuit education is to prepare students to serve the Common Good. Human-Centered Engineering at Boston College integrates technical knowledge, creativity, and a humanistic perspective to address societal challenges and opportunities. What societal problems are important to you and how will you use your HCE education to solve them? (400-word limit)",
                maxWordCount: 400,
                tag: "Optional",
                supplementalEssayCategory: "Why This School/Major",
            },
        ],
    },
    rutgers: {
        prompts: [
            {
                id: "2024_rutgers_honors_essay",
                title: "Honors Essay",
                text: "How have you challenged yourself to embrace new experiences and continue learning about yourself and the world? Also, how will your experiences enhance the Rutgers University–New Brunswick Honors community? Aim for about 500 words.",
                maxWordCount: 500,
                tag: "Optional",
            },
        ],
    },
    tufts: {
        prompts: [
            {
                id: "2024_tufts_college_specific_-_arts_and_sciences_or_engineering_option_1",
                title: "College Specific - Arts and Sciences or Engineering Option 1",
                text: "It's cool to love learning. What excites your intellectual curiosity and why? (200-250 words)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_tufts_college_specific_-_arts_and_sciences_or_engineering_option_2",
                title: "College Specific - Arts and Sciences or Engineering Option 2",
                text: "How have the environments or experiences of your upbringing – your family, home, neighborhood, or community – shaped the person you are today? (200-250 words)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_tufts_college_specific_-_arts_and_sciences_or_engineering_option_3",
                title: "College Specific - Arts and Sciences or Engineering Option 3",
                text: "Using a specific example or two, tell us about a way that you contributed to building a collaborative and/or inclusive community. (200-250 words)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_tufts_college_specfic_-_smfa",
                title: "College Specfic - SMFA",
                text: "Art has the power to disrupt our preconceptions, shape public discourse, and imagine new ways of being in the world. What are the ideas you'd like to explore in your work? (200-250 words)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_tufts_supplement_3",
                title: "Complete the Sentence",
                text: "In addition, we will ask all applicants to complete this sentence in 100 words or less:\n\n“I am applying to Tufts because…”",
                maxWordCount: 100,
                supplementalEssayCategory: "Tiny Prompt",
            },
        ],
    },
    uwashington: {
        prompts: [
            {
                id: "2024_uwashington_supplement_1",
                title: "Essay 1",
                text: "Tell a story from your life, describing an experience that either demonstrates your character or helped to shape it. (650-word limit)",
                maxWordCount: 650,
            },
            {
                id: "2024_uwashington_supplement_2",
                title: "Short Response 1",
                text: "Our families and communities often define us and our individual worlds. Community might refer to your cultural group, extended family, religious group, neighborhood or school, sports team or club, co-workers, etc. Describe the world you come from and how you, as a product of it, might add to the diversity of the UW. (300-word limit)",
                maxWordCount: 300,
            },
            {
                id: "2024_uwashington_honors_essay",
                title: "Honors Essay",
                text: "We want to understand your enthusiasm for learning unfamiliar things and exploring different ways of thinking. \n\nTell us why you are excited to push your education outside the areas of learning you are most comfortable with. (450-word limit)",
                maxWordCount: 450,
                tag: "Optional",
            },
        ],
    },
    bu: {
        prompts: [
            {
                id: "2024_bu_supplement_1_option_1",
                title: "Essay Option 1",
                text: "Reflect on a social or community issue that deeply resonates with you. Why is it important to you, and how have you been involved in addressing or raising awareness about it? (300-word limit)\n",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_bu_supplement_1_option_2",
                title: "Essay Option 2",
                text: "What about being a student at BU most excites you? How do you hope to contribute to our campus community? (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
        ],
    },
    osu: {
        prompts: [
            {
                id: "2024_osu_morrill_scholarship_program",
                title: "Morrill Scholarship Program",
                text: "In what ways have your life experiences and/or endeavors prepared you to be an active Morrill Scholar who will champion OSU's Shared Values of “diversity and innovation” and “inclusion and equity” while investing in our culture of service reflective of our land grant mission?  Please answer fully, and when possible, provide specific examples. (500-word limit)",
                maxWordCount: 500,
                tag: "Optional",
            },
        ],
    },
    purdue: {
        prompts: [
            {
                id: "2024_purdue_question_1",
                title: "Question 1",
                text: "How will opportunities at Purdue support your interests, both in and out of the classroom? (250-word limit)",
                maxWordCount: 250,
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_purdue_question_2",
                title: "Question 2",
                text: "Briefly discuss your reasons for pursuing the major you have selected. (250-word limit)",
                maxWordCount: 250,
                supplementalEssayCategory: "Why This School/Major",
            },
        ],
    },
    umd: { prompts: [] },
    lehigh: {
        prompts: [
            {
                id: "2024_lehigh_essay_1",
                title: "Essay 1",
                text: "How did you first learn about Lehigh University and what motivated you to apply? (200-word limit)",
                maxWordCount: 200,
            },
            {
                id: "2024_lehigh_essay_2",
                title: "Essay 2",
                text: "Our 10-year strategic plan, Inspiring the Future Makers, outlines three goals that articulate a vision for how we innovate, make an impact and do that work together—because together, we do better work.\n\nReflect on how your personal background—be it academic, cultural, extracurricular, family, gender, racial, religious, or another aspect—will contribute to Lehigh's aim to make it new, make a difference, (and/or) make it together. (300-word limit)",
                maxWordCount: 300,
            },
        ],
    },
    texasam: {
        prompts: [
            {
                id: "2024_texasam_supplement_1",
                title: "Essay 1",
                text: 'Tell us your story. What unique opportunities or challenges have you experienced throughout your high school career that have shaped who you are today?" (750-word limit)',
                maxWordCount: 750,
            },
            {
                id: "2024_texasam_short_answer_1",
                title: "Short Answer 1",
                text: "Describe a life event which you feel has prepared you to be successful in college. (250-word limit)",
                maxWordCount: 250,
            },
            {
                id: "2024_texasam_short_answer_2",
                title: "Short Answer 2",
                text: "Tell us about the person who has most impacted your life and why. (250-word limit)",
                maxWordCount: 250,
            },
        ],
    },
    uga: {
        prompts: [
            {
                id: "2024_uga_supplement_1",
                title: "Essay 1",
                text: "The transition from middle to high school is a key time for students as they reach new levels of both academic and personal discovery. Please share a book (novel, non-fiction, etc.) that had a serious impact on you during this time. Please focus more on why this book made an impact on you and less on the plot/theme of the book itself (we are not looking for a book report). (200-300 words)",
                maxWordCount: 350,
                supplementalEssayCategory: "Life Experience",
            },
        ],
    },
    urochester: {
        prompts: [
            {
                id: "2024_urochester_supplement_1",
                title: "Essay 1",
                text: "The University of Rochester benefactor, entrepreneur, photography pioneer and philanthropist George Eastman said, “The progress of the world depends almost entirely upon education.” In what ways do you envision using the curricular flexibility and co-curricular opportunities at the University of Rochester to promote progress and change within the communities you inhabit? (250 word limit)",
                maxWordCount: 250,
            },
            {
                id: "2024_urochester_combined_degree_essay",
                title: "Combined Degree Essay",
                text: "If you are interested in applying to one of the Combined Degree Programs, please answer the following essay prompt and limit your response to no more than 250 words.\n\nWhat relevant experiences or insight have led to your interest in this particular program? Describe how Rochester's resources - facilities, faculty, peers and research - will meet your academic and personal ambitions. Describe how you see yourself contributing to the challenging field you want to enter as a Rochester graduate. (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
        ],
    },
    virginiatech: {
        prompts: [
            {
                id: "2024_virginiatech_supplement_1",
                title: "Essay 1",
                text: "Virginia Tech's motto is \"Ut Prosim\" which means 'That I May Serve'. Share how you contribute to a community that is important to you. How long have you been involved? What have you learned and how would you like to share that with others at Virginia Tech?",
                maxWordCount: 120,
            },
            {
                id: "2024_virginiatech_supplement_2",
                title: "Essay 2",
                text: "Virginia Tech's Principles of Community supports access and inclusion by affirming the dignity and value of every person, respecting differences, promoting mutual understanding and open expression, and strives to eliminate bias and discrimination. Have you had an experience when you or someone you know were not being included? Did you reach out to anyone for assistance, direction, or resources? Were you able to affect change and/or influence others? Did this experience change your perspective and if so, how?",
                maxWordCount: 120,
            },
            {
                id: "2024_virginiatech_supplement_3",
                title: "Essay 3",
                text: "Share a time when you were most proud of yourself either as a role model or when you displayed your leadership. What specific skills did you contribute to the experience? How did others rely on you for guidance? What did you learn about yourself during this time?",
                maxWordCount: 120,
            },
            {
                id: "2024_virginiatech_supplement_4",
                title: "Essay 4",
                text: "Describe a goal that you have set and the steps you will take to achieve it. What made you set this goal for yourself? What is your timeline to achieve this goal? Who do you seek encouragement or guidance from and how do they support your progress as you work on this goal?",
                maxWordCount: 120,
            },
        ],
    },
    wakeforest: {
        prompts: [
            {
                id: "2024_wakeforest_question_1",
                title: "Question 1",
                text: "Why have you decided to apply to Wake Forest? Share with us anything that has made you interested in our institution. (150 words)",
                maxWordCount: 150,
                supplementalEssayCategory: "Why This School/Major",
            },
            {
                id: "2024_wakeforest_question_2",
                title: "Question 2",
                text: "Tell us what piques your intellectual curiosity or has helped you understand the world's complexity. This can include a work you've read, a project you've completed for a class, and even co-curricular activities in which you have been involved. (150-word limit)",
                maxWordCount: 150,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_wakeforest_question_3",
                title: "Question 3",
                text: "Dr. Maya Angelou, renowned author, poet, civil-rights activist, and former Wake Forest University Reynolds Professor of American Studies, inspired others to celebrate their identities and to honor each person's dignity. Choose one of Dr. Angelou's powerful quotes. How does this quote relate to your lived experience or reflect how you plan to contribute to the Wake Forest community? (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
                supplementalEssayCategory: "Life Experience",
            },
            {
                id: "2024_wakeforest_list_1",
                title: "List 1",
                text: "List five books you have read that intrigued you. (Spaces have been left for you to include each book's title and author and mark whether the selection was required or not required.)",
                maxWordCount: 75,
                tag: "Optional",
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_wakeforest_list_2",
                title: "List 2",
                text: "Give us your top ten list.\nTheme: _____________________________\n10.\n9.\n8.\n7.\n6.\n5.\n4.\n3.\n2.\n1.",
                maxWordCount: 75,
                tag: "Optional",
                supplementalEssayCategory: "Tiny Prompt",
            },
        ],
    },
    casewestern: {
        prompts: [
            {
                id: "2024_casewestern_pre-professional_scholars_prompt_1",
                title: "Pre-Professional Scholars Prompt 1",
                text: "By applying to the Pre-Professional Scholars Program, you are applying to gain admission to professional school earlier than students who apply in the traditional way. Please indicate why you're interested in your chosen profession. How do you see yourself being particularly suited to this field? What events and/or experiences have led you to your choice? This essay should be between 250 and 500 words in length.",
                maxWordCount: 500,
                tag: "Optional",
            },
            {
                id: "2024_casewestern_pre-professional_scholars_prompt_2",
                title: "Pre-Professional Scholars Prompt 2",
                text: "In the college application process, you are constantly prompted for a list of your achievements, awards, and accomplishments. While this information is useful to us, we are interested in hearing more about you. Describe an event, achievement, or experience of which you are particularly proud but that will not show up on a resume, may not garner any recognition, and does not appear anywhere else on your admission application. This essay should not exceed 750 words in length.",
                maxWordCount: 750,
                tag: "Optional",
            },
        ],
    },
    floridastate: {
        prompts: [
            {
                id: "2024_floridastate_honors_supplemental_prompt",
                title: "Honors Supplemental Prompt",
                text: "FSU's Honors Signature courses are unique, innovative courses taught by our dedicated full-time Honors Teaching Faculty. Design your own Honors Signature course exploring a complex topic that is important to you. Name the course and provide a brief (2 - 3 sentence) description for the website. Describe the learning outcomes for enrolled students, highlighting the personal and academic benefits of the course. Propose a course assignment that connects the students with a campus resource, faculty member, or organization to enhance their hands-on learning experience (Limit 300 words).",
                maxWordCount: 300,
                tag: "Optional",
            },
        ],
    },
    northeastern: { prompts: [] },
    umn: {
        prompts: [
            {
                id: "2024_umn_short_answer_1",
                title: "Short Answer 1",
                text: "The University values diversity, broadly defined to include diversity of experiences, perspectives, backgrounds, and talents. Enrolling a diverse community of scholars interested in learning with and from each other fosters discussion and discovery inside and outside of the classroom. Please share briefly how you might contribute to, or benefit from, our community of scholars. (150-word limit)",
                maxWordCount: 150,
            },
            {
                id: "2024_umn_essay_1",
                title: "Essay 1",
                text: "Please include an explanation of why you would like to study the major(s) you have selected. You may also use this space to indicate your interest in other major(s) in the colleges listed above. (150-word limit)",
                maxWordCount: 150,
                tag: "Optional",
            },
        ],
    },
    williamandmary: {
        prompts: [
            {
                id: "2024_williamandmary_essay_option_1",
                title: "Essay Option 1",
                text: "William & Mary is a community that fosters deep human connection. We reflect on the lessons of history to meet the challenges of a rapidly changing world. We engage diverse perspectives and seek wisdom in bridging differences. Together, we are unceasing in our efforts to make a meaningful difference in our communities, the state, the nation, and the world.\n\nAre there any particular communities that are important to you, and how do you see yourself being a part of our community? (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_williamandmary_essay_option_2",
                title: "Essay Option 2",
                text: "William & Mary is a community that fosters deep human connection. We reflect on the lessons of history to meet the challenges of a rapidly changing world. We engage diverse perspectives and seek wisdom in bridging differences. Together, we are unceasing in our efforts to make a meaningful difference in our communities, the state, the nation, and the world.\n\nShare more about a personal academic interest or career goal. (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_williamandmary_essay_option_3",
                title: "Essay Option 3",
                text: "William & Mary is a community that fosters deep human connection. We reflect on the lessons of history to meet the challenges of a rapidly changing world. We engage diverse perspectives and seek wisdom in bridging differences. Together, we are unceasing in our efforts to make a meaningful difference in our communities, the state, the nation, and the world.\n\nHow has your family, culture and/or background shaped your lived experience? (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_williamandmary_essay_option_4",
                title: "Essay Option 4",
                text: "William & Mary is a community that fosters deep human connection. We reflect on the lessons of history to meet the challenges of a rapidly changing world. We engage diverse perspectives and seek wisdom in bridging differences. Together, we are unceasing in our efforts to make a meaningful difference in our communities, the state, the nation, and the world.\n\nWhat led to your interest in William & Mary? (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_williamandmary_essay_option_5",
                title: "Essay Option 5",
                text: "William & Mary is a community that fosters deep human connection. We reflect on the lessons of history to meet the challenges of a rapidly changing world. We engage diverse perspectives and seek wisdom in bridging differences. Together, we are unceasing in our efforts to make a meaningful difference in our communities, the state, the nation, and the world.\n\nTell us about a challenge or adversity you've experienced and how that has impacted you as an individual. (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_williamandmary_essay_option_6",
                title: "Essay Option 6",
                text: "William & Mary is a community that fosters deep human connection. We reflect on the lessons of history to meet the challenges of a rapidly changing world. We engage diverse perspectives and seek wisdom in bridging differences. Together, we are unceasing in our efforts to make a meaningful difference in our communities, the state, the nation, and the world.\n\nIf we visited your town, what would you want to show us? (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
        ],
    },
    stonybrook: {
        prompts: [
            {
                id: "2024_stonybrook_program_specific_-_honors,_wise,_university_scholars_essay",
                title: "Program Specific - Honors, WISE, University Scholars Essay",
                text: "Please discuss why you are a good fit for your preferred University Honors Program (Honors College, University Scholars, or WISE). (approximately 250 words)",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_stonybrook_program_specific_-_scholars_for_medicine,_scholars_for_dental_medicine_essay",
                title: "Program Specific - Scholars for Medicine, Scholars for Dental Medicine Essay",
                text: "What aspects about medicine/dental medicine intrigue you? Describe how these aspects influence your life. (650-word limit)",
                maxWordCount: 650,
                tag: "Optional",
            },
        ],
    },
    uconn: {
        prompts: [
            {
                id: "2024_uconn_personal_essay",
                title: "Personal Essay",
                text: "Include a thoughtful commentary on your academic goals; an explanation for leaving your present/most recently attended college; and your reasoning for wanting to attend the University of Connecticut.\n\nIf your educational attendance has not been continuous, you must include information about the activities you were involved in during the time(s) not spent in school. (250-650 words)",
                maxWordCount: 650,
            },
        ],
    },
    brandeis: {
        prompts: [
            {
                id: "2024_brandeis_essay_1",
                title: "Essay 1",
                text: "Brandeis was established 75 years ago to address antisemitism, racism, and gender discrimination in higher education, and today, the university remains dedicated to its founding values of inclusivity and justice. How has your educational experience shaped your perspective on these values? (250-word limit)",
                maxWordCount: 250,
            },
            {
                id: "2024_brandeis_international_students_essay",
                title: "International Students Essay",
                text: "What excites you the most about being an international student at Brandeis University? (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
        ],
    },
    michiganstate: { prompts: [] },
    ncstate: {
        prompts: [
            {
                id: "2024_ncstate_short_answer_1",
                title: "Short Answer 1",
                text: "Explain why you selected the first choice academic program above and why you are interested in studying this at NC State. (250-word limit)",
                maxWordCount: 250,
            },
            {
                id: "2024_ncstate_honors_program_essay",
                title: "Honors Program Essay",
                text: "Tell us about an event, realization, or project where your curiosity inspired you to take action. What initially sparked your interest, how did you pursue further understanding, and what specific actions did you take as a result? (600-word limit)",
                maxWordCount: 600,
                tag: "Optional",
            },
        ],
    },
    pennstate: {
        prompts: [
            {
                id: "2024_pennstate_supplement_1",
                title: "Essay 1",
                text: "Please tell us something about yourself, your experiences, or activities that you believe would reflect positively on your ability to succeed at Penn State. This is your opportunity to tell us something about yourself that is not already reflected in your application or academic records. We suggest a limit of 500 words or fewer.",
                maxWordCount: 500,
                tag: "Optional",
            },
        ],
    },
    rpi: {
        prompts: [
            {
                id: "2024_rpi_prompt_1",
                title: "Prompt 1",
                text: "Why are you interested in Rensselaer Polytechnic Institute? (250-word limit)",
                maxWordCount: 250,
            },
            {
                id: "2024_rpi_prompt_2",
                title: "Prompt 2",
                text: "Please briefly elaborate on one of your extracurricular activities or work experiences. (300-word limit)",
                maxWordCount: 300,
            },
            {
                id: "2024_rpi_program_specific_-_accelerated_physician-scientist_(b.s./m.d.)_program_essay",
                title: "Program Specific - Accelerated Physician-Scientist (B.S./M.D.) Program Essay",
                text: "State your reasons for aspiring to a career in medicine. (approximately 500-750 words)",
                maxWordCount: 750,
                tag: "Optional",
            },
            {
                id: "2024_rpi_program_specific_-_architecture_(arch)",
                title: "Program Specific - Architecture (ARCH)",
                text: "State your reasons for choosing architecture as your profession. (1-2 pages)",
                maxWordCount: 1500,
                tag: "Optional",
            },
            {
                id: "2024_rpi_program_specific_-_electronic_arts_(eart)_essay",
                title: "Program Specific - Electronic Arts (EART) Essay",
                text: "Discuss your interests in the field of electronic arts, and state how this is reflected in your portfolio, if one has been submitted. (1-2 pages)",
                maxWordCount: 1500,
                tag: "Optional",
            },
            {
                id: "2024_rpi_program_specific_-_games_and_simulation_arts_and_sciences_(gsas)_essay",
                title: "Program Specific - Games and Simulation Arts and Sciences (GSAS) Essay",
                text: "State your reasons for choosing the field of games and simulation arts and sciences, making reference to your portfolio if one has been submitted. (1-2 pages)",
                maxWordCount: 1500,
                tag: "Optional",
            },
            {
                id: "2024_rpi_program_specific_-_music_(musc)_essay",
                title: "Program Specific - Music (MUSC) Essay",
                text: "Describe your interest in the music major and your goals. Please include completed course work in music, private music instruction, musical performance and production experience, or completed independent projects. (1-2 pages)",
                maxWordCount: 1500,
                tag: "Optional",
            },
        ],
    },
    santaclara: {
        prompts: [
            {
                id: "2024_santaclara_essay_1",
                title: "Essay 1",
                text: "At Santa Clara University, we push our students to be creative, be challenged, and be the solution. Think about an ethical dilemma that you care about that our society is currently facing. This can be something happening in your local community or more globally. How can an SCU education help you prepare for and address this challenge? (150-300 words)",
                maxWordCount: 300,
            },
            {
                id: "2024_santaclara_essay_2",
                title: "Essay 2",
                text: "At Santa Clara University, we push our students to be creative, be challenged, and be the solution. Think about an ethical dilemma that you care about that our society is currently facing. This can be something happening in your local community or more globally. How can an SCU education help you prepare for and address this challenge? (150-300 words)",
                maxWordCount: 300,
            },
            {
                id: "2024_santaclara_optional_short_answer",
                title: "Optional Short Answer",
                text: "Why are you interested in pursuing the [Division or Major] selected above? (50 words)",
                maxWordCount: 50,
                tag: "Optional",
                supplementalEssayCategory: "Tiny Prompt",
            },
        ],
    },
    gwu: {
        prompts: [
            {
                id: "2024_gwu_essay_option_1",
                title: "Essay Option 1",
                text: "At the George Washington University, our students frequently interact with policymakers and world leaders. These experiences and those of our alumni can shape the future of global affairs. If you had the power to change the course of history in your community or the world, what would you do and why? (500-word limit)",
                maxWordCount: 500,
                tag: "Optional",
            },
            {
                id: "2024_gwu_essay_option_2",
                title: "Essay Option 2",
                text: "The George Washington University encourages students to think critically and to challenge the status quo. Thus, civil discourse is a key characteristic of our community. Describe a time when you engaged others in meaningful dialogue around an issue that was important to you. Did this exchange create change, new perspectives, or deeper relationships? (500-word limit)",
                maxWordCount: 500,
                tag: "Optional",
            },
        ],
    },
    syracuse: {
        prompts: [
            {
                id: "2024_syracuse_essay_1",
                title: "Essay 1",
                text: "Syracuse University is a place that seeks to be welcoming to all – and has been since our founding. Explain why you are interested in Syracuse University and describe a personal experience in which you persevered through adversity, rejected discrimination, learned a lesson, or were inspired by the courageous actions of others and how you will apply what you learned to our community in a positive way. (250-word limit)",
                maxWordCount: 250,
            },
            {
                id: "2024_syracuse_discovery_program_essay",
                title: "Discovery Program Essay",
                text: "Explain why you are interested in this opportunity, detailing how you have previously adapted to a new environment, culture, language, or time spent away from your family. How would you expect spending your first semester abroad to change you? (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
        ],
    },
    umass: {
        prompts: [
            {
                id: "2024_umass_supplement_1",
                title: "Essay 1",
                text: "Please tell us why you want to attend UMass Amherst? (100-word limit)",
                maxWordCount: 100,
            },
            {
                id: "2024_umass_supplement_2",
                title: "Essay 2",
                text: "At UMass Amherst, no two students are alike. Our communities and groups often define us and shape our individual worlds. Community can refer to various aspects, including shared geography, religion, race/ethnicity, income, ideology, and more. Please choose one of your communities or groups and describe its significance. Explain how, as a product of this community or group, you would enrich our campus. (100-word limit)",
                maxWordCount: 100,
            },
            {
                id: "2024_umass_supplement_3",
                title: "Essay 3",
                text: "Please tell us why you chose the Majors you did? (100-word limit)",
                maxWordCount: 100,
            },
        ],
    },
    umiami: {
        prompts: [
            {
                id: "2024_umiami_supplement_1",
                title: "Essay 1",
                text: "Located within one of the most dynamic cities in the world, the University of Miami is a distinctive community with a variety of cultures, traditions, histories, languages, and backgrounds. The University of Miami is a values-based and purpose-driven postsecondary institution that embraces diversity and inclusivity in all its forms and strives to create a culture of belonging, where every person feels valued and has an opportunity to contribute.\n\nPlease describe how your unique experiences, challenges overcome, or skills acquired would contribute to our distinctive University community. (250-word limit)",
                maxWordCount: 250,
            },
        ],
    },
    pitt: {
        prompts: [
            {
                id: "2024_pitt_short_answer_option_1",
                title: "Short Answer Option 1",
                text: "What is something you would like to see change in the world? Explain why. (around 200-300 words)",
                maxWordCount: 750,
                tag: "Optional",
            },
            {
                id: "2024_pitt_short_answer_option_2",
                title: "Short Answer Option 2",
                text: "If you had 10 minutes and the attention of a million people, what would your TED Talk be about? (around 200-300 words)",
                maxWordCount: 750,
                tag: "Optional",
            },
            {
                id: "2024_pitt_short_answer_option_3",
                title: "Short Answer Option 3",
                text: "What does it mean to be “educated”? (around 200-300 words)",
                maxWordCount: 750,
                tag: "Optional",
            },
        ],
    },
    binghamton: { prompts: [] },
    iubloomington: {
        prompts: [
            {
                id: "2024_iubloomington_supplement_1",
                title: "Essay 1",
                text: "Describe your academic and career plans and any special interests (e.g., undergraduate research, academic interests, leadership opportunities, etc.) that you are eager to pursue as an undergraduate at Indiana University. If you encountered any unusual circumstances, challenges, or obstacles in completing your education, share those experiences and how you overcame them. (200-400 words)",
                maxWordCount: 400,
            },
        ],
    },
    tulane: {
        prompts: [
            {
                id: "2024_tulane_essay_1",
                title: "Essay 1",
                text: "Describe why you are interested in joining the Tulane community. Consider your experiences, talents, and values to illustrate what you would contribute to the Tulane community if admitted. (250-word limit)",
                maxWordCount: 250,
            },
        ],
    },
    comines: {
        prompts: [
            {
                id: "2024_comines_essay_1",
                title: "Essay 1",
                text: "Reflect on a time when you had to collaborate with individuals whose backgrounds, culture, experiences, or viewpoints are different from your own. How did this experience impact your understanding of your own identity and how do you think this experience will influence your approach in building inclusive teams? (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_comines_essay_2",
                title: "Essay 2",
                text: "Please share a bit more about your academic interests. Why did you choose Mines and what do you hope to study? If you are undecided, what area(s) of study are you considering? What has inspired your interests in these areas? Think about your prior/current coursework, extracurricular activities, work/volunteer experiences, future goals, or anything else that has shaped your interests. (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
        ],
    },
    pepperdine: {
        prompts: [
            {
                id: "2024_pepperdine_supplement_1",
                title: "Essay 1",
                text: "Pepperdine is a Christian university where all are welcomed and encouraged to challenge each other in the pursuit of truth. Students, faculty, and staff members from all backgrounds participate in conversations of faith inside and outside of the classroom. Considering that Pepperdine is a Christian university, why are you interested in attending and how would you contribute to conversations of faith on campus? (300-500 words)",
                maxWordCount: 500,
            },
        ],
    },
    stevenstech: {
        prompts: [
            {
                id: "2024_stevenstech_essay_1",
                title: "Essay 1",
                text: "Stevens Institute of Technology's motto is “Inspired by humanity, powered by technology.”  What does this motto mean to you, and why does it inspire you to pursue your education at Stevens? Please provide an essay between 250 and 500 words in length. Use the full range if you need it, but don't feel obligated to do so. (250-500 words)",
                maxWordCount: 500,
            },
        ],
    },
    ubuffalo: {
        prompts: [
            {
                id: "2024_ubuffalo_honors_college_essay",
                title: "Honors College Essay",
                text: "UB Honors scholars are characterized by intellectual curiosity, a broad range of interests, and a commitment to a diverse and inclusive society. What experiences have helped to broaden your perspectives academically, socially and culturally? What experiences do you hope to have that will enlarge your understanding of the cultural richness of America and the world? Please be as specific as possible in your answer. (650-word limit)",
                maxWordCount: 650,
                tag: "Optional",
            },
        ],
    },
    udelaware: { prompts: [] },
    rutgersnewark: { prompts: [] },
    uillinoischicago: {
        prompts: [
            {
                id: "2024_uillinoischicago_program_choice_essay",
                title: "Program Choice Essay",
                text: "Please provide an essay that explains why you chose your intended program of study. What interests you the most about this major? Please be specific - those evaluating these essays are highly interested in your response. If Undecided, what areas of study do you look forward to studying in college? (50-500 words)",
                maxWordCount: 500,
            },
            {
                id: "2024_uillinoischicago_honors_college_essay_1",
                title: "Honors College Essay 1",
                text: "Please describe in detail ways that you have sought out academic challenges and personal growth opportunities while in high school. Examples can include activities both inside or outside of your school. (400-500 words)",
                maxWordCount: 500,
                tag: "Optional",
            },
            {
                id: "2024_uillinoischicago_honors_college_essay_2",
                title: "Honors College Essay 2",
                text: "How will your engagement with the Honors College foster your academic, personal, and professional growth? (400-500 words)",
                maxWordCount: 500,
                tag: "Optional",
            },
        ],
    },
    wpi: {
        prompts: [
            {
                id: "2024_wpi_supplement_1",
                title: "Essay 1",
                text: "Worcester Polytechnic Institute seeks students who are the right fit for its academic and campus community. In what ways are you the right fit for the distinctive educational and campus experience that Worcester Polytechnic Institute offers? (500-word limit)",
                maxWordCount: 500,
            },
        ],
    },
    clemson: {
        prompts: [
            {
                id: "2024_clemson_honors_college_supplement",
                title: "Honors College Essay 1",
                text: "Tell us about your academic interests and professional goals (to the extent that you have identified them at this point). What experiences, talents, accomplishments, and/or characteristics inspired and contributed to these goals? What avenues have you explored to learn more about this or to gain experience in this area to date? [Note: This is your opportunity to tell us who you are as a thinker. Please focus your essay on you, your experiences, and your ideas, not on what the Honors College or Clemson offers.] (650-word limit)",
                maxWordCount: 650,
            },
        ],
    },
    marquette: {
        prompts: [
            {
                id: "2024_marquette_program_specific_-_physical_therapy,_athletic_training_applicants",
                title: "Program Specific - Physical Therapy, Athletic Training Applicants",
                text: "Why are you interested in physical therapy and/or athletic training as a profession, and what experiences have led you to this interest? (about 300 words)",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_marquette_transfer_essay",
                title: "Transfer Essay",
                text: "Please reflect on your decision to transfer to Marquette University. How is Marquette a better fit for you to finish your college career? (about 300 words)",
                maxWordCount: 300,
                tag: "Optional",
            },
        ],
    },
    njit: {
        prompts: [
            {
                id: "2024_njit_honors_essay",
                title: "Honors Essay",
                text: "The Honors College develops leaders who are engaged members of their community and profession. How have you been of service to your community (either as a volunteer or in a work environment) and what has that taught you about being a leader? How will you contribute to the Honors community? (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_njit_accelerated_essay",
                title: "Accelerated Essay",
                text: "Why do you want to pursue an accelerated program in medicine, law, dentistry, optometry, physical therapy or physician assistant, and what academic, educational or other experiences have provided you with a foundation for the accelerated program? (400-word limit)",
                maxWordCount: 400,
                tag: "Optional",
            },
        ],
    },
    fordham: {
        prompts: [
            {
                id: "2024_fordham_essay_option_1",
                title: "Essay Option 1",
                text: "At Fordham, we expect students to care for and engage with their communities and be active citizens for positive change. Please share an experience you had that caused you to develop a new perspective, change your point of view, and/or empower you to take an action or be courageous. Your response should include examples of your personal growth (e.g., what did you learn, did your point of view change, did you develop new skills or strengths?). (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_fordham_essay_option_2",
                title: "Essay Option 2",
                text: "Fordham, as a Jesuit university, recognizes the dignity, uniqueness and potential of each person. A Fordham education is student-centered and rooted in close collaboration among students, faculty, and staff. Describe how you would contribute to our campus community as an actively engaged learner and leader. Specifically draw on your personal story, identity, experiences, strengths, and perspectives. (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_fordham_essay_option_3",
                title: "Essay Option 3",
                text: "Our motto is “New York is my campus, Fordham is my school.” New York City is a diverse and global city that provides Fordham students with a special kind of educational experience, full of both challenge and opportunity. What has prepared you to embrace the unique opportunity of living and learning in New York City? (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_fordham_essay_option_4",
                title: "Essay Option 4",
                text: "Is there something that you are proud of that you would like to share with the Admission Committee? (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
        ],
    },
    temple: { prompts: [] },
    usouthflorida: { prompts: [] },
    auburn: { prompts: [] },
    baylor: {
        prompts: [
            {
                id: "2024_baylor_essay_1",
                title: "Essay 1",
                text: "What are you looking for in a university, why do you want to attend Baylor, and how do you see yourself contributing to the Baylor community? (450-word limit)",
                maxWordCount: 450,
            },
        ],
    },
    gonzaga: {
        prompts: [
            {
                id: "2024_gonzaga_short_answer_option_1",
                title: "Short Answer Option 1",
                text: "A Gonzaga education promotes dedication to human dignity, social justice, diversity, global engagement, solidarity with the vulnerable, and environmental stewardship. Reflecting on these values, how have you shown your commitment to standing for and with others in your actions? (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
            {
                id: "2024_gonzaga_short_answer_option_2",
                title: "Short Answer Option 2",
                text: "Gonzaga's Presidential Speaker's Series invites leaders from around the world (such as LTC Olga Custodio, America's first Latina military pilot or Cotopaxi founder Davis Smith) to share their work and passions with the GU community. If you were able to invite any living person to come and speak to the GU community, who would it be? Why would you want to invite them? (300-word limit)",
                maxWordCount: 300,
                tag: "Optional",
            },
        ],
    },
    lmu: {
        prompts: [
            {
                id: "2024_lmu_essay_1",
                title: "Essay 1",
                text: "Share with us why you are interested in LMU and/or why you chose your specific area of study or major. (500-word limit)",
                maxWordCount: 500,
            },
        ],
    },
    uiowa: { prompts: [] },
    drexel: {
        prompts: [
            {
                id: "2024_drexel_essay_1",
                title: "Essay 1",
                text: "Reflect on your experiences, personal characteristics, and unique traits that have prepared you for the challenges and opportunities associated with your chosen major. How have these things shaped your goals, aspirations, and potential contributions to your field of study? (350-500 words)",
                maxWordCount: 500,
            },
        ],
    },
    iit: {
        prompts: [
            {
                id: "2024_iit_essay_1",
                title: "Essay 1",
                text: "Tell us why you are pursuing your selected major. If you are undecided on a major tell us about the academic areas of interest to you. Include how you hope to use your IIT education to support them in achieving your passions and goals. (250-word limit)",
                maxWordCount: 250,
            },
            {
                id: "2024_iit_essay_2",
                title: "Essay 2",
                text: "Why are you interested in Illinois Institute of Technology? (250-word limit)",
                maxWordCount: 250,
            },
        ],
    },
    rit: { prompts: [] },
    uoregon: {
        prompts: [
            {
                id: "2024_uoregon_essay_1",
                title: "Essay 1",
                text: "Describe an experience with discrimination, whether it was fighting against discrimination or recognizing your contribution to discriminating against a person or group. What did you learn from the experience? In what ways will you bring those lessons to the University of Oregon? (500-word limit)",
                maxWordCount: 500,
            },
        ],
    },
    usd: {
        prompts: [
            {
                id: "2024_usd_supplement_1",
                title: "Essay 1",
                text: "For over 75 years, the liberal arts have been the cornerstone of the educational experience at USD. They help students explore the world, past and present, and lay the foundation for building inspired and meaningful lives. They also provide the skills and experiences students need to be successful in an increasingly changing and challenging world. Throughout the first year and beyond, we engage students in this exploration through our Core Curriculum and Learning Communities, connecting them with faculty who will guide them as they become global citizens. What excites you about the academic opportunities at USD? (350-word limit)",
                maxWordCount: 350,
            },
            {
                id: "2024_usd_supplement_2_option_1",
                title: "Essay 2 Option 1",
                text: "Here at USD, we value the fact that our students come from all walks of life, have experienced different realities and bring with them an array of unique perspectives, including some that have often been underrepresented and underserved by higher education. Our Horizon Project is a call to action, ensuring that we are dedicating the time, energy and resources necessary to build an ever more diverse, inclusive and equitable campus community. What contribution have you made to your current school and/or local community that best exemplifies your awareness of and commitment to these ideals? (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
            {
                id: "2024_usd_supplement_2_option_2",
                title: "Essay 2 Option 2",
                text: "As a proud Changemaker Campus, the USD experience emphasizes changemaking through care for our common home, social justice efforts, civic engagement, social innovation, creativity and a global perspective. Write about one of the challenges facing humanity today. Why does it matter to you? What experiences or insights have you had that speak to the urgency of this issue. (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
            {
                id: "2024_usd_supplement_2_option_3",
                title: "Essay 2 Option 3",
                text: "Share some of your ideas about the role of faith or spirituality in your life or the life of someone you know. As a contemporary Catholic university, we welcome and celebrate students from every background and faith tradition, including those who do not identify with a faith tradition. We devote great time and energy to ensuring all students feel connected to the vibrant USD community, including opportunities to grow in their relationship with God while considering who they are, what they believe and who they are called to become. (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
        ],
    },
    asu: { prompts: [] },
    csulb: { prompts: [] },
    sdsu: { prompts: [] },
    slo: { prompts: [] },
    williams: { prompts: [] },
    amherst: {
        prompts: [
            {
                id: "2024_amherst_supplement_1_option_1",
                title: "Option A Prompt 1",
                text: "Amherst College is committed to learning through close colloquy and to expanding the realm of knowledge through scholarly research and artistic creation at the highest level. Its graduates link learning with leadership—in service to the College, to their communities, and to the world beyond. – from the Mission of Amherst College. What do you see as the benefits of linking learning with leadership and/or service? In your response, please share with us a time where you have seen that benefit through your own experience. (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
            {
                id: "2024_amherst_supplement_1_option_2",
                title: "Option A Prompt 2",
                text: "We seek an Amherst made stronger because it includes those whose experiences can enhance our understanding of our nation and our world. We do so in the faith that our humanity is an identity forged from diversity, and that our different perspectives enrich our inquiry, deepen our knowledge, strengthen our community, and prepare students to engage with an ever-changing world. - from the Trustee Statement on Diversity and Community. In what ways could your unique experiences enhance our understanding of our nation and our world? (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
            {
                id: "2024_amherst_supplement_1_option_3",
                title: "Option A Prompt 3",
                text: "Strong commitment to the freedom of inquiry lies at the heart of Amherst College's mission to create a home in which the liberal arts may flourish. As a small residential liberal arts college that prides itself on the ability, curiosity, and diversity of its students, Amherst seeks to create a respectful environment in which members of its community feel emboldened to pursue their intellectual and creative passions. – from the Amherst College Statement of Academic and Expressive Freedom. Tell us about an intellectual or creative passion you have pursued; what did you learn about yourself through that pursuit? (350-word limit)",
                maxWordCount: 350,
                tag: "Optional",
            },
        ],
    },
    navalacademy: { prompts: [] },
    pomona: {
        prompts: [
            {
                id: "2024_pomona_supplement_1",
                title: "Essay 1",
                text: "What do you love about the subject(s) you selected as potential major(s)? If undecided, share more about one of your academic passions. (150-word limit)",
                maxWordCount: 150,
            },
            {
                id: "2024_pomona_supplement_2_option_1",
                title: "Essay 2 Option 1",
                text: "At Pomona, we celebrate and identify with the number 47. Share with us one of your quirky personal, family, or community traditions and why you hold on to it. (150-word limit)",
                maxWordCount: 150,
                tag: "Optional",
            },
            {
                id: "2024_pomona_supplement_2_option_2",
                title: "Essay 2 Option 2",
                text: "What item are you excited to bring with you to college? (150-word limit)",
                maxWordCount: 150,
                tag: "Optional",
            },
            {
                id: "2024_pomona_supplement_2_option_3",
                title: "Essay 2 Option 3",
                text: "Describe a time when you felt empowered or on top of the world? (150-word limit)",
                maxWordCount: 150,
                tag: "Optional",
            },
            {
                id: "2024_pomona_supplement_3_option_1",
                title: "Essay 3 Option 1",
                text: "In the past few years, is there something you have changed your mind about? Why? (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_pomona_supplement_3_option_2",
                title: "Essay 3 Option 2",
                text: "Reflecting on a community that you are part of, what values or perspectives from that community would you bring to Pomona? (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_pomona_supplement_3_option_3",
                title: "Essay 3 Option 3",
                text: "What strength or quality do you have that most people might not see or recognize? (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
        ],
    },
    swarthmore: {
        prompts: [
            {
                id: "2024_swarthmore_supplement_1",
                title: "Essay 1",
                text: "What aspects of your self-identity or personal background are most significant to you? Reflecting on the elements of your home, school, or other communities that have shaped your life, explain how you have grown in your ability to navigate differences when engaging with others, or demonstrated your ability to collaborate in communities other than your own. (150-250 words)",
                maxWordCount: 250,
            },
            {
                id: "2024_swarthmore_supplement_2",
                title: "Essay 2",
                text: "Tell us about a topic that has fascinated you recently – either inside or outside of the classroom. What made you curious about this? Has this topic connected across other areas of your interests? How has this experience shaped you and what encourages you to keep exploring? (150-250 words)",
                maxWordCount: 250,
            },
        ],
    },
    wellesley: {
        prompts: [
            {
                id: "2024_wellesley_supplement_1",
                title: "Essay 1",
                text: "Wellesley students actively seek ways to build bridges and to change the world for the better. Tell us about an experience working with and alongside people of different backgrounds and/or perspectives from your own. Why was this important to you, and what lessons from this will you bring with you to Wellesley? (500-word limit)",
                maxWordCount: 500,
            },
        ],
    },
    airforceacademy: {
        prompts: [
            {
                id: "2024_airforceacademy_candidate_statement_1",
                title: "Candidate Statement 1",
                text: "Describe a setback or ethical dilemma that you have faced. How did you resolve it? How did the outcome affect you? Most importantly, what did you learn about yourself and how would you handle a similar situation in the future? (500-word limit)",
                maxWordCount: 500,
            },
            {
                id: "2024_airforceacademy_candidate_statement_2",
                title: "Candidate Statement 2",
                text: "Describe a leadership experience that has meaning for you. A leadership role can mean more than just a title. It can mean being a mentor to others, acting as the person in charge of a specific task, or taking the lead role in organizing an event or project. Think about what you accomplished and what you learned from the experience. What were your responsibilities? (500-word limit)",
                maxWordCount: 500,
            },
            {
                id: "2024_airforceacademy_candidate_statement_3",
                title: "Candidate Statement 3",
                text: "Please provide any additional information or background you believe will be of assistance in evaluating your application. (500-word limit)",
                maxWordCount: 500,
            },
        ],
    },
    westpoint: {
        prompts: [
            {
                id: "2024_westpoint_candidate_statement_1",
                title: "Candidate Statement 1",
                text: "In the space below, Explain why you want to attend the United States Military Academy and serve on active duty as an Army officer.",
                maxWordCount: 500,
            },
            {
                id: "2024_westpoint_candidate_statement_2",
                title: "Candidate Statement 2",
                text: "Think of some things in the past that were difficult for you. Pick one and discuss what steps you took to address it. Include whether you turned to anyone, the role that person played, and what you learned about your character as a result of this challenge.",
                maxWordCount: 500,
            },
            {
                id: "2024_westpoint_candidate_statement_3",
                title: "Candidate Statement 3",
                text: "West Point and the Army are committed to the idea that respect for others and an understanding of diversity are important leadership traits. Why will you be successful in working with leaders, peers, and subordinates of a gender, color, ethnicity, and/or religion different from your own?",
                maxWordCount: 500,
            },
        ],
    },
    bowdoin: {
        prompts: [
            {
                id: "2024_bowdoin_short_answer",
                title: "Short Answer",
                text: "How did you first learn about Bowdoin? (140-character limit)",
                maxWordCount: 30,
                supplementalEssayCategory: "Tiny Prompt",
            },
            {
                id: "2024_bowdoin_supplement_1",
                title: "Essay 1",
                text: "The Offer of the College represents Bowdoin's values. You have the option to reflect on the line you selected and how it has meaning to you. (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
            {
                id: "2024_bowdoin_essay_2",
                title: "Essay 2",
                text: "If you wish, you may share anything about the unique experiences and perspectives that you would bring with you to the Bowdoin campus and community or an experience you have had that required you to navigate across or through difference. (250-word limit)",
                maxWordCount: 250,
                tag: "Optional",
            },
        ],
    },
    carleton: {
        prompts: [
            {
                id: "2024_carleton_supplement_1",
                title: "Essay 1",
                text: "Think about someone you connect with who's different from you. What do you find most meaningful about your interactions with them? (300 word limit)",
                maxWordCount: 300,
            },
        ],
    },
    cmc: {
        prompts: [
            {
                id: "2024_cmc_supplement_1",
                title: "Essay 1",
                text: "CMC's mission is to prepare students for thoughtful and productive lives and responsible leadership in business, government, and the professions. With this mission in mind, please explain why you want to attend Claremont McKenna College. (250-word limit)",
                maxWordCount: 250,
            },
            {
                id: "2024_cmc_essay_2",
                title: "Essay 2",
                text: "Describe a time when engaging with someone about a specific topic resulted in you changing your attitude, belief, or behavior, or you changed the belief or behavior of someone else. What was the change that occurred for you, and what facilitated that change? What did you learn from that experience, and how has it informed how you engage with others? (250-word limit)",
                maxWordCount: 250,
            },
        ],
    },
    harveymudd: {
        prompts: [
            {
                id: "2024_harveymudd_supplement_1",
                title: "Essay 1",
                text: "Harvey Mudd College seeks to educate engineers, scientists, and mathematicians well versed in all of these areas and in the humanities, social sciences and the arts so that they may assume leadership in their fields with a clear understanding of the impact of their work on society.\n\n“Scientific research is a human endeavor. The choices of topics that we research are based on our biases, our beliefs, and what we bring: our cultures and our families. The kinds of problems that people put their talents to solving depends on their values.” – Dr. Clifton Poodry\n\nHow has your own background influenced the types of problems you want to solve, the people you want to work with, and the impact you hope your work can have? (500-word limit)",
                maxWordCount: 500,
            },
            {
                id: "2024_harveymudd_supplement_2",
                title: "Essay 2",
                text: "Many students choose HMC because they don't want to give up their interests in the Humanities, Social Sciences and the Arts – or HSA as we call it at HMC. Briefly describe what you'd like to learn about in your dream HSA class. (100 words or less)",
                maxWordCount: 100,
            },
        ],
    },
    hamilton: {
        prompts: [
            {
                id: "2024_hamilton_essay_1",
                title: "Essay 1",
                text: "At Hamilton, we each bring different backgrounds and perspectives, and we teach one another about the world through our individual and shared experiences. In the spirit of Hamilton's motto, Know Thyself, please reflect on your unique perspective and how Hamilton might shape it, as well as how your perspective will shape Hamilton. (350-word limit)",
                maxWordCount: 350,
            },
        ],
    },
    alabama: { prompts: [] },
    risd: {
        prompts: [
            {
                id: "2024_risd_brown_|_risd_dual_degree_supplemental_essay",
                title: "Brown | RISD Dual Degree Supplemental Essay",
                text: "The Brown|RISD Dual Degree Program (BRDD) draws on the complementary strengths of Brown University and Rhode Island School of Design (RISD) to provide students with the opportunity to explore and engage with diverse spheres of academic and creative inquiry. The culmination of students’ five-year program is a capstone project that relates and integrates content, approaches, and methods from two distinct learning experiences. Considering your understanding of the academic programs at Brown and RISD, describe how and why the specific blend of RISD's experimental, immersive combined studio and liberal arts program and Brown's wide-ranging courses and curricula could constitute an optimal undergraduate education for you. Additionally, how might your academic, artistic and personal experiences contribute to the Dual Degree community and its commitment to interdisciplinary work? (650 word limit)",
                maxWordCount: 650,
            },
        ],
    },
    accd: {
        prompts: [
            {
                id: "2024_accd_short_answer_essay",
                title: "Short Answer Essay",
                text: "Describe the path that has led to your choice of major/program and why you believe an ArtCenter education will further your educational and career goals. (200 words recommended)",
                maxWordCount: 200,
            },
            {
                id: "2024_accd_essay_1",
                title: "Essay 1",
                text: "ArtCenter believes that our community is enriched when our classrooms are filled with individuals with different lived experiences and points of view. Tell us how your lived experience informs your point of view as a creative and as an individual. Have you encountered challenges associated with what makes you unique and, if so, how have you addressed these challenges? (400 words recommended)",
                maxWordCount: 400,
            },
            {
                id: "2024_accd_program_specific_-_game_design_essay_2",
                title: "Program Specific - Game Design Essay 2",
                text: "Why do you want to be a game designer? (400 words recommended)",
                maxWordCount: 400,
                tag: "Optional",
            },
            {
                id: "2024_accd_program_specific_-_interaction_design_essay_2",
                title: "Program Specific - Interaction Design Essay 2",
                text: "Describe the innovations you think will happen in Interaction Design over the next five years. Describe why you think these innovations will occur and how you see yourself working in the field of interaction in the future. (400 words recommended)",
                maxWordCount: 400,
                tag: "Optional",
            },
        ],
    },
    sva: {
        prompts: [
            {
                id: "2024_sva_statement_of_intent",
                title: "Statement of Intent",
                text: "Discuss your reasons for pursuing undergraduate study in the visual arts. (approximately 500 words)",
                maxWordCount: 500,
            },
        ],
    },
    parsons: {
        prompts: [
            {
                id: "2024_parsons_essay_1",
                title: "Essay 1",
                text: "In your study or work at The New School, what social issue or system would you make the focus of your efforts to effect change? (200-400 words)",
                maxWordCount: 400,
            },
            {
                id: "2024_parsons_essay_2",
                title: "Essay 2",
                text: "What specific aspects of The New School's academic programs or community drew you to apply? Please pay particular attention to the college and program that you have applied to in your essay. (200-400 words)",
                maxWordCount: 400,
            },
        ],
    },
    pratt: {
        prompts: [
            {
                id: "2024_pratt_essay_1",
                title: "Essay 1",
                text: "Write about a piece of work (visual art, musical performance, piece of writing, architecture, or design) that has influenced you and describe the impact it has had on your perspective OR write about a part of your work and the impact of creating the work has had on you. (250-500 words)",
                maxWordCount: 500,
            },
            {
                id: "2024_pratt_construction_management_essay",
                title: "Construction Management Essay",
                text: "Write about a construction project (building, home, stadium, structure, resort, or environment) or a piece of art or design that has inspired you. Describe the impact it has had on your decision to study Construction. (250-500 words)",
                maxWordCount: 500,
                tag: "Optional",
            },
        ],
    },
    scad: { prompts: [] },
    mica: { prompts: [] },
};

export default essayPrompts;
