import BaseButton from "../base/BaseButton";
import loadingSpinner from "../../imgs/loading.svg";
import styles from "../../styles/auth/CleverOAuthPage.module.css";
import { useAuthContext } from "./AuthContext";

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function CleverLoginErrorPage({ errorMessage }) {
    const navigate = useNavigate();
    return (
        <div className={styles.error_page_container}>
            <div className={styles.error_message}>Oops! Something went wrong.</div>
            <div className={styles.error_description}>
                <p>There was a problem while trying to log you in with Clever. Please try logging in again.</p>
                <p>If this issue persists, please reach out to support@athenaco.ai.</p>
                <p>
                    Error Message: <b>{errorMessage}</b>
                </p>
            </div>
            <BaseButton className={styles.button} onClick={() => navigate("/login")}>
                Back to login
            </BaseButton>
        </div>
    );
}

function CleverLoginLoadingPage() {
    return (
        <div className={styles.loading_page_container}>
            <img className={styles.loading_page_spinner} src={loadingSpinner} alt="loading spinner" />
            <div className={styles.loading_message}>Logging you in...</div>
        </div>
    );
}

export default function CleverOAuthPage() {
    const [loginError, setLoginError] = useState("");
    const { isLoggedIn, handleCleverLogin } = useAuthContext();

    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const navigate = useNavigate();

    useEffect(() => {
        if (!code) return;
        if (isLoggedIn) {
            navigate("/");
            return;
        }

        const formData = { code: code };
        handleCleverLogin(formData).catch((error) => setLoginError(error?.response?.data?.error));
    }, [code, isLoggedIn, handleCleverLogin, navigate]);

    if (!code) {
        return (
            <div className={styles.clever_oauth_page_container}>
                <CleverLoginErrorPage errorMessage={"Missing authorization code"} />
            </div>
        );
    }

    return (
        <div className={styles.clever_oauth_page_container}>
            {loginError ? <CleverLoginErrorPage errorMessage={loginError} /> : <CleverLoginLoadingPage />}
        </div>
    );
}
