import styles from "../../../styles/application/sidebar/ApplicationSidebar.module.css";
import { useApplicationContext } from "../ApplicationContext";
import EssayReview from "./EssayReview";
import EssayGuidance from "./EssayGuidance";
import NextSteps from "./NextSteps";
import BaseButton from "../../base/BaseButton";
import DoubleChevronRightIcon from "../../../imgs/double-chevron-right-icon.png";
import { useUserContext } from "../../auth/UserContext";

export default function ApplicationSidebar() {
    const { showSidebar, toggleSidebar, activeSidebarTab, updateSidebarTab } = useApplicationContext();
    const { canSeeNextStepsUI } = useUserContext();

    const handleSwitchTab = (mainTab, subTab) => {
        updateSidebarTab(mainTab, subTab);
    };

    const sidebarBody = () => {
        if (activeSidebarTab.mainTab === "guidance") {
            return <EssayGuidance />;
        } else if (activeSidebarTab.mainTab === "review") {
            if (activeSidebarTab.subTab === "reportCard") {
                return <EssayReview />;
            } else if (activeSidebarTab.subTab === "nextSteps") {
                return <NextSteps />;
            }
        }

        return null;
    };

    return (
        <div
            className={`${styles.athena_sidebar_container} ${
                showSidebar ? styles.open_sidebar : styles.closed_sidebar
            }`}
        >
            <div className={styles.sidebar_header}>
                {showSidebar && (
                    <BaseButton
                        className={`${styles.button} ${styles.close_button}`}
                        onClick={() => toggleSidebar(false)}
                    >
                        <img
                            className={styles.button_icon}
                            src={DoubleChevronRightIcon}
                            alt="button for closing athena sidebar"
                        />
                    </BaseButton>
                )}
            </div>
            <div className={styles.tabs_container}>
                <div
                    className={`${styles.tab} ${activeSidebarTab.mainTab === "guidance" && styles.active_tab}`}
                    onClick={() => handleSwitchTab("guidance", "")}
                >
                    Essay Guidance
                </div>
                <div
                    className={`${styles.tab} ${activeSidebarTab.mainTab === "review" && styles.active_tab}`}
                    onClick={() => handleSwitchTab("review", "")}
                >
                    Essay Review
                </div>
            </div>
            {canSeeNextStepsUI() && activeSidebarTab.mainTab === "review" && (
                <div className={styles.tabs_container}>
                    <div
                        className={`${styles.tab} ${activeSidebarTab.subTab === "reportCard" && styles.active_tab}`}
                        onClick={() => handleSwitchTab("review", "reportCard")}
                    >
                        Report Card
                    </div>
                    <div
                        className={`${styles.tab} ${activeSidebarTab.subTab === "nextSteps" && styles.active_tab}`}
                        onClick={() => handleSwitchTab("review", "nextSteps")}
                    >
                        Next Steps
                    </div>
                </div>
            )}
            {showSidebar && <div className={styles.sidebar_content_container}>{sidebarBody()}</div>}
        </div>
    );
}
