import React, { createContext, useCallback, useContext, useState } from "react";
import { Logtail } from "@logtail/browser";

const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_ATHENA_FRONTEND_SOURCE_TOKEN);

const UserContext = createContext();

export function useUserContext() {
    return useContext(UserContext);
}

/*
 * UserProvider is a fix to the provider refresh bug that happens when we reload the page.
 * This bug happens because our previous provider lived inside the BrowserRouter, which
 * refreshes whenever the page is hard reloaded.
 *
 * THIS IS THE HIGHEST-LEVEL PROVIDER (besides GoogleOAuthProvider) in the frontend.
 *
 * TODO (Eugene): NAMING NEEDS TO BE DONE BETTER--we have a UserProvider and AuthProvider
 * but Auth lowkey should be at the top level
 */
export function UserProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    /*
     * User Profiles
     *
     * We have two user profiles:
     * 1. userProfile: The profile of the logged-in user.
     * 2. frontendActiveProfile: The profile of the user who is taking actions on the frontend.
     *
     * The frontendActiveProfile is used when an account is taking actions on behalf of another user.
     * For example, consultants don't have membership access for their own accounts, but they can
     * register accounts on behalf of their students. In this case, the consultant's profile is the
     * userProfile and the student's profile is the frontendActiveProfile.
     *
     * For normal users, the userProfile and frontendActiveProfile are the same.
     */
    const [userProfile, setUserProfile] = useState({});
    const [frontendActiveProfile, setFrontendActiveProfile] = useState({});

    const canSeeNextStepsUI = useCallback(() => {
        return userProfile?.email === "eshao573@gmail.com" || userProfile?.email === "ajnatarajan@gmail.com";
    }, [userProfile]);

    return (
        <UserContext.Provider
            value={{
                userProfile,
                setUserProfile,
                frontendActiveProfile,
                setFrontendActiveProfile,
                isLoggedIn,
                setIsLoggedIn,
                // Feature Gating
                canSeeNextStepsUI,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}
